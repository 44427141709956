import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import RoundTripSkeleton from "./RoundTripSkeleton";
import RoundTripDetail from "./RoundTripDetail";
import { RiRadioButtonLine } from "react-icons/ri";
import { IoRadioButtonOff } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RoundList = ({
  search,
  isLoading,
  destinationCity,
  destinationCity2,
  startDate,
  filteredInboundData,
  handleChnageCurrency,
  selectedFlight,
  InboundResullt,
  handleClickDetail,
  activeId,
}) => {
  const { errors } = useSelector((state) => state.flight);
  const navigate = useNavigate();
  useEffect(() => {
    if (errors) {
      navigate("/404"); // Navigating to the specified route
    }
  }, []);

  return (
    <Col
      lg={6}
      md={6}
      sm={6}
      xs={6}
      lgPull={6}
      className="cus_col_6"
      style={{ paddingRight: "0px" }}
    >
      {search && !isLoading ? (
        <div>
          {search.length !== 0 ? (
            <div className="content-main">
              <div className="block_content roundtrip_header">
                <ul>
                  <li className="flight_namess">
                    {destinationCity} <i className="fa fa-arrow-right" />{" "}
                    {destinationCity2}
                  </li>
                  <li className="date">
                    {startDate ? startDate.format("MM/DD/YYYY") : ""}
                  </li>
                  <li className="prev_next_btn">
                    <a id="lnkOutBoundPrevDay">Prev Day</a>
                    <span />
                    <a id="lnkOutBoundNextDay" href="javascript:;">
                      Next Day
                    </a>
                  </li>
                </ul>
                <div className="clearfix" />
              </div>
              <div className="block_content">
                <div className="flight_info">
                  <ul>
                    <li>
                      <a o="" href="javascript:;">
                        Airlines
                      </a>
                    </li>
                    <li>
                      <a onclick="DepartSortRoundOneWay()" href="javascript:;">
                        Depart <i className="depasorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="depasortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="depasorting"
                        defaultValue="descending"
                      />
                    </li>
                    <li>
                      <a
                        onclick="RoundDurationSortOneWay()"
                        href="javascript:;"
                      >
                        Duration <i className="durasorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="durasortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="durasorting"
                        defaultValue="descending"
                      />
                    </li>
                    <li>
                      <a onclick="ArriveSortRoundOneWay()" href="javascript:;">
                        Arrive <i className="arriveasorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="arrivesortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="arrivesorting"
                        defaultValue="descending"
                      />
                    </li>
                    <li className="price_control">
                      <a onclick="PriceSortDRTO()" href="javascript:;">
                        Price <i className="pricesorta fa fa-arrow-up" />
                        <i
                          style={{ display: "none" }}
                          className="pricesortd fa fa-arrow-down"
                        />
                      </a>
                      <input
                        type="hidden"
                        id="pricesorting"
                        defaultValue="descending"
                      />
                    </li>
                  </ul>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="round_trip_list">
                <div className="refendable11 refendable11onward">
                  {filteredInboundData.map((flight, index) => (
                    <div className="price1" key={index}>
                      <div className="price111 price111onword">
                        <div className="flight11">
                          <div className="stopscount">
                            <div
                              id="divOB2"
                              className="Price8083 allshow block-content-2 custom_block_content flight-list-v2 DRTFilter 6E Indigo 0Stops bingo_button_4"
                            >
                              <div className="box-result custom_box_result active">
                                <div className="flight_namess obflight_namess">
                                  {flight.sI[0].fD.aI.name}
                                  <span className="flight_no">
                                    {flight.sI[0].fD.fN}
                                  </span>
                                </div>
                                <ul className="list-search-resultss result_list">
                                  <li>
                                    <img
                                      src={`/Images/${flight.sI[0].fD.aI.code}.png`}
                                      alt=""
                                    />
                                  </li>
                                  <li className="price priced">
                                    <span className="airlineprice">
                                      <span className="mainprice">
                                        ₹ {flight.overallFares.totalFare}
                                      </span>
                                    </span>
                                  </li>
                                  <li className="round_check">
                                    <div className="checkbox-default">
                                      <span>
                                        {selectedFlight === flight ? (
                                          <RiRadioButtonLine
                                            color="#5091fa"
                                            size={25}
                                            className="cursor-pointer"
                                            onClick={() =>
                                              InboundResullt(flight)
                                            }
                                          />
                                        ) : (
                                          <IoRadioButtonOff
                                            color="#817b7b"
                                            size={25}
                                            className="cursor-pointer"
                                            onClick={() =>
                                              InboundResullt(flight)
                                            }
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                  <li className="pad_left10">
                                    <span className="date departdate">
                                      {new Date(
                                        flight.sI[0].dt
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })}
                                    </span>
                                    {flight.sI[0].da.city}
                                  </li>
                                  <li>
                                    <span className="duration departdur">
                                      {flight.sI[0].duration} m
                                      <span>
                                        <div className="cus_tooltip">
                                          {flight.sI.length - 1 == 0
                                            ? "Non-Stop"
                                            : `${flight.sI.length - 1} Stops`}
                                        </div>
                                      </span>
                                    </span>
                                  </li>
                                  <li className="pad_left10">
                                    <span className="date arivedate">
                                      {new Date(
                                        flight.sI[flight.sI.length - 1].at
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })}
                                    </span>
                                    {flight.sI[flight.sI.length - 1].aa.city}
                                  </li>
                                </ul>
                                <div className="clearfix" />
                                <div className="flight_details">
                                  <a
                                    className={`details_btn flightdetailajax cursor-pointer`}
                                    onClick={() => {
                                      handleClickDetail(
                                        flight.totalPriceList[0].id
                                      );
                                    }}
                                  >
                                    Fight Details
                                  </a>

                                  <div className="clearfix" />
                                </div>
                              </div>
                              {activeId === flight.totalPriceList[0].id && (
                                <RoundTripDetail
                                  idx={flight.totalPriceList[0].id}
                                  flight={flight}
                                  handleClickDetail={handleClickDetail}
                                  handleChnageCurrency={handleChnageCurrency}
                                />
                                // idx={flight.ResultIndex}
                                // flight={flight}
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="round_trip_not_found">
              {/* <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.hostinger.in%2Ftutorials%2Fhow-to-fix-error-404&psig=AOvVaw2xOtPPomn-AoIQbyGgaiFN&ust=1715245883559000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCIj4l__a_YUDFQAAAAAdAAAAABAE" alt="" /> */}
              No Data Found
            </div>
          )}
        </div>
      ) : (
        // <RoundTripSkeleton />
        <div>
          {errors ? <div>Loading................</div> : <RoundTripSkeleton />}
        </div>
      )}
    </Col>
  );
};

export default RoundList;
