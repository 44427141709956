import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
// import "./FlightList.css";
import { MdFlight, MdOutlineFlight } from "react-icons/md";
import leftArrow from "../FlightList/images/leftArrow.png";
import rightArrow from "../FlightList/images/rightArrow.png";
import { GiAirplaneDeparture } from "react-icons/gi";
import { ImPriceTags } from "react-icons/im";
import { GiAirplaneArrival } from "react-icons/gi";
import { GoStopwatch } from "react-icons/go";
import { FlightListInfo, formatTime } from "../FlightList/FlightListInfo";
import { BsArrowLeftRight } from "react-icons/bs";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaFilter, FaRupeeSign } from "react-icons/fa";
import { flightSearch } from "../../../redux/services/operations/flight";
import { useSelector, useDispatch } from "react-redux";
// import FlightListSkeleton from "./FlightListSkeleton";
import { DayPickerRangeController } from "react-dates";
import {
  IoRadioButtonOff,
  IoRadioButtonOffSharp,
  IoRadioButtonOnOutline,
  IoRadioButtonOnSharp,
} from "react-icons/io5";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { cities, fares } from "../BookingSearch/FlightBooking";
import moment from "moment";
import Slider from "rc-slider";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import "rc-slider/assets/index.css";
import { RxCross2 } from "react-icons/rx";
import { FaChevronDown } from "react-icons/fa";
// import { cities2 } from "../BookingSearch/Cities";
import { cities12 } from "../../../Cities";
// import FlightListCard from "./FlightListCard";
// import FilterBar from "./FilterBar";
// import ReSearchForm from "./ReSearchForm";
import axios from "axios";
import ReSearchForm from "../FlightList/ReSearchForm";
import FilterBar from "../FlightList/FilterBar";
import InternationalFlightListCard from "./InternationalFlightListCard";
import FlightListSkeleton from "../FlightList/FlightListSkeleton";

const sliderItems = [
  { date: "Oct 03", price: "7845" },
  { date: "Oct 04", price: "5954" },
  { date: "Oct 05", price: "4155" },
  { date: "Oct 06", price: "5953" },
  { date: "Oct 07", price: "5495" },
  { date: "Oct 08", price: "5953" },
  { date: "Oct 09", price: "4155" },
];

const CalenderSliderContent = styled.div`
  border: 1px solid rgb(228, 228, 228);
  border-right: none;
  border-block: none;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FlightInternational = () => {
  // const [minFare, setMinFare] = useState(500);
  // const [maxFare, setMaxFare] = useState(10000);
  // const [sliderValue, setSliderValue] = useState([500, 5000]);
  const [fromCurrency, setFromCurrency] = useState("INR");
  const [toCurrency, setToCurrency] = useState("USD");
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);

  const [fromCurrency2, setFromCurrency2] = useState("IRR");
  const [toCurrency2, setToCurrency2] = useState("INR");
  const [exchangeRate2, setExchangeRate2] = useState(null);

  useEffect(() => {
    const URL = `https://admin.lelotrip.com/api/currency_convert/${fromCurrency}/${toCurrency}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        console.log("ratetetesafdfs", rate);
        setExchangeRate(rate);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };
    if (fromCurrency !== toCurrency) {
      getExchangeRate(URL);
    } else {
      setExchangeRate(1);
    }
    // getExchangeRate()
  }, []);

  useEffect(() => {
    const URL = `https://admin.lelotrip.com/api/currency_convert/${fromCurrency2}/${toCurrency2}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        console.log("rate IRR to INR", rate);
        setExchangeRate2(rate);
      } catch (error) {
        console.error("Error fetching exchange rate2:", error);
      }
    };
    if (fromCurrency2 !== toCurrency2) {
      getExchangeRate(URL);
    } else {
      setExchangeRate2(1);
    }
  }, []);

  const handleChnageCurrency = (amount) => {
    if (!isNaN(amount) && exchangeRate) {
      const convertedValue = amount * exchangeRate;
      return convertedValue.toFixed(2);
    }
  };

  const handleChangeCurrency2 = (amount) => {
    if (!isNaN(amount) && exchangeRate2) {
      const convertedValue = amount * exchangeRate2;
      return convertedValue.toFixed(2);
    }
  };

  const [tripsActive, setTripsActive] = useState(false);
  const [travellerActive, setTravellerActive] = useState(false);
  // const [activeId, setActiveId] = useState(1);
  // const { token } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data: routeParams } = useParams();
  const dispatch = useDispatch();

  const sessionId = localStorage.getItem("sessionId");
  const traceId = String(sessionStorage.getItem("traceId"));
  const search = useSelector((state) => state.flight.search);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [activeId2, setActiveId2] = useState(null);
  const [rooms, setRooms] = useState([{ adults: 1, children: 0, infants: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [calVisible, setCalVisible] = useState(false);
  const [clickDestination, SetClickDestination] = useState(false);
  const [clickDestination2, SetClickDestination2] = useState(false);
  const [destinationCity, setDestinationCity] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [destinationCity2, setDestinationCity2] = useState("");
  const [selectedCityCode2, setSelectedCityCode2] = useState("");
  const [showModal, setShowModal] = useState(null);
  const [Visible, setVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [checkedStops, setCheckedStops] = useState([
    "non-stop",
    "1-stop",
    "2-stop",
  ]);

  // const InboundResullt = (flight) => {
  //   console.log("Selected Inbound flight:", flight);
  //   setSelectedFlight(flight);
  // };

  // const InboundResullt2 = (flight) => {
  //   console.log("Selected Outbound flight:", flight);
  //   setSelectedFlight2(flight);
  // };
  const [deptimeRange, setdepTimeRange] = useState([0, 0]);
  const [arrtimeRange, setarrTimeRange] = useState([0, 0]);
  const [filteredInboundData, setFilteredInboundData] = useState([]);
  const [filteredOutboundData, setFilteredOutboundData] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [minFare, setMinFare] = useState(0);
  const [maxFare, setMaxFare] = useState(0);

  useEffect(() => {
    const searchData = parseSearchParams(routeParams);
    setDataSearch(searchData);
    updateRoomsData(searchData);
    updateDates(searchData);
    if (parseInt(searchData.JourneyType) === 1) handleSearchFlight();
    else if (parseInt(searchData.JourneyType) === 2) handleSearchFlightRound();
    else if (parseInt(searchData.JourneyType) === 3)
      handleSearchFlightMultiPle();
    setDestinationCity(searchData.Segments[0].Origin);
    setDestinationCity2(searchData.Segments[0].Destination);
    setSearchInput(searchData.Segments[0].Origin);
    setSearchInput2(searchData.Segments[0].Destination);
    // console.log("cabin class flight", searchData.Segments[0].FlightCabinClass)
    setSelectedOption(parseInt(searchData.Segments[0].FlightCabinClass));

    const destCity = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Origin
    );
    const destCity2 = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Destination
    );
    setDestination1(destCity);
    setDestination2(destCity2);
  }, [routeParams]);

  const cabinmapping = {
    1: "Default",
    2: "Y",
    3: "Y",
    4: "C",
    5: "C",
    6: "F",
  };
  console.log("searchhhhh", search);

  useEffect(() => {
    if (dataSearch) {
      setIsLoading(true);
      const updatedSearchData = { ...dataSearch };
      updatedSearchData.TokenId = token;
      updatedSearchData.EndUserIp = "192.168.10.10";
      console.log("Search Data", updatedSearchData);

      const searchdataTJ_Round = {
        searchQuery: {
          cabinClass: "ECONOMY",
          paxInfo: {
            ADULT: updatedSearchData.AdultCount.toString(),
            CHILD: updatedSearchData.ChildCount.toString(),
            INFANT: updatedSearchData.InfantCount.toString(),
          },
          routeInfos: [
            {
              fromCityOrAirport: {
                code: updatedSearchData.Segments[0].Origin,
              },
              toCityOrAirport: {
                code: updatedSearchData.Segments[0].Destination,
              },
              travelDate: updatedSearchData.Segments[0].PreferredDepartureTime,
            },
            {
              fromCityOrAirport: {
                code: updatedSearchData.Segments[1].Origin,
              },
              toCityOrAirport: {
                code: updatedSearchData.Segments[1].Destination,
              },
              travelDate: updatedSearchData.Segments[1].PreferredArrivalTime,
            },
          ],
          searchModifiers: {
            isDirectFlight: true,
            isConnectingFlight: true,
          },
        },
      };

      const searchdataParto = {
        PricingSourceType: "All",
        RequestOption: "All",
        SessionId: sessionId,
        AdultCount: updatedSearchData.AdultCount.toString(),
        ChildCount: updatedSearchData.ChildCount.toString(),
        InfantCount: updatedSearchData.InfantCount.toString(),
        TravelPreference: {
          CabinType:
            cabinmapping[updatedSearchData.Segments[0].FlightCabinClass],
          MaxStopsQuantity: "All",
          AirTripType: "Return",
        },
        OriginDestinationInformations: [
          {
            DepartureDateTime:
              updatedSearchData.Segments[0].PreferredDepartureTime,
            DestinationLocationCode: updatedSearchData.Segments[0].Destination,
            DestinationType: "2",
            OriginLocationCode: updatedSearchData.Segments[0].Origin,
            OriginType: "2",
          },
          {
            DepartureDateTime:
              updatedSearchData.Segments[1].PreferredDepartureTime,
            DestinationLocationCode: updatedSearchData.Segments[1].Destination,
            DestinationType: "2",
            OriginLocationCode: updatedSearchData.Segments[1].Origin,
            OriginType: "2",
          },
        ],
      };

      const searchDataInternational = {
        origin: updatedSearchData.Segments[0].Origin,
        destination: updatedSearchData.Segments[0].Destination,
        departureDate: updatedSearchData.Segments[0].PreferredDepartureTime,
        returnDate: updatedSearchData.Segments[1].PreferredDepartureTime,
        adult: updatedSearchData.AdultCount.toString(),
        child: updatedSearchData.ChildCount.toString(),
        infant: updatedSearchData.InfantCount.toString(),
        type: 2,
        cabin: updatedSearchData.Segments[0].FlightCabinClass,
        tboToken: token,
        partocrsSession: sessionId,
      };

      dispatch(flightSearch(searchdataTJ_Round, false, true, navigate))
        // dispatch(flightSearch(searchDataInternational, navigate))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [dataSearch, dispatch, navigate, token]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    setBreakpoints();
    return () => window.removeEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   if (search && search.length > 1) {
  //     InboundResullt(search[0]);
  //     InboundResullt2(search[1]);
  //   }
  // }, [search]);

  // useEffect(() => {
  //   if (search && search.length > 0) {
  //     const airlineNames = search.flatMap((option) =>
  //       option.OriginDestinationOptions.flatMap((destination) =>
  //         destination.FlightSegments.map((segment) => ({
  //           name: segment.OperatingAirline.EquipmentName,
  //           selected: true,
  //         }))
  //       )
  //     );

  //     const uniqueAirlines = Array.from(
  //       new Set(airlineNames.map((airline) => airline.name))
  //     );

  //     const uniqueAirlinesWithSelected = uniqueAirlines.map((airlineName) => ({
  //       name: airlineName,
  //       selected: true,
  //     }));

  //     setAirlines(uniqueAirlinesWithSelected);
  //     console.log("airlines", uniqueAirlinesWithSelected);
  //   }
  // }, [search]);

  const [adultCount, setAdultCount] = useState("");
  const [childCount, setChildCount] = useState("");
  const [infantCount, setInfantCount] = useState("");
  const [updatedSearch, setUpdatedSearch] = useState({});
  // useEffect(() => {
  //   if (Object.keys(search.COMBO).length > 0) {
  //     const updatedSearchData = { ...search.COMBO };

  //     for (let key in updatedSearchData) {
  //       if (
  //         updatedSearchData.hasOwnProperty(key) &&
  //         Array.isArray(updatedSearchData[key])
  //       ) {
  //         updatedSearchData[key] = updatedSearchData[key].map((flight) => {
  //           const adultFare = flight.totalPriceList[0].fd.ADULT.fC;
  //           const childFare = flight.totalPriceList[0].fd.CHILD
  //             ? flight.totalPriceList[0].fd.CHILD.fC
  //             : { TF: 0, NF: 0, BF: 0, TAF: 0 };
  //           const infantFare = flight.totalPriceList[0].fd.INFANT
  //             ? flight.totalPriceList[0].fd.INFANT.fC
  //             : { TF: 0, NF: 0, BF: 0, TAF: 0 };

  //           const totalBaseFare =
  //             adultFare.BF * adultCount +
  //             childFare.BF * childCount +
  //             infantFare.BF * infantCount;
  //           const totalNetFare =
  //             adultFare.NF * adultCount +
  //             childFare.NF * childCount +
  //             infantFare.NF * infantCount;
  //           const totalTax =
  //             adultFare.TAF * adultCount +
  //             childFare.TAF * childCount +
  //             infantFare.TAF * infantCount;
  //           const totalFare =
  //             adultFare.TF * adultCount +
  //             childFare.TF * childCount +
  //             infantFare.TF * infantCount;

  //           return {
  //             ...flight,
  //             overallFares: {
  //               totalBaseFare,
  //               totalNetFare,
  //               totalTax,
  //               totalFare,
  //               adultCount,
  //               childCount,
  //               infantCount,
  //             },
  //           };
  //         });
  //       }
  //     }

  //     setUpdatedSearch(updatedSearchData);
  //   }
  // }, [search, dataSearch]);




  
  











  useEffect(() => {
    if (search && search.COMBO) {
      // Initialize an empty array to hold unique airline names
      const extractedAirlines = [];

      // Iterate through search.COMBO and extract airline names
      search.COMBO.forEach((combo) => {
        // Check if combo.sI is defined and is an array before iterating over it
        if (combo.sI && Array.isArray(combo.sI)) {
          combo.sI.forEach((flight) => {
            const airlineName = flight.fD.aI.name;
            if (!extractedAirlines.some((airline) => airline.name === airlineName)) {
              extractedAirlines.push({
                name: airlineName,
                selected: false, // Initially not selected
              });
            }
          });
        }
      });

      // Set the extracted airlines to the state
      setAirlines(extractedAirlines);
    }
  }, [search]);

  // useEffect(() => {
  //   if (search) {
  //     const allFares = search.COMBO[0].totalPriceList.flatMap(
  //       (flight) => flight.fd.fC.TF
  //     );

  //     const minFare = Math.min(...allFares);
  //     const maxFare = Math.max(...allFares);

  //     setMinFare(minFare);
  //     setMaxFare(maxFare);
  //     setSliderValue([minFare, maxFare]);
  //   }
  // }, [search]);


  useEffect(() => {
    if (search?.COMBO?.length > 0) {
      // Extract all "TF" values from the totalPriceList
      const allFares = search.COMBO.flatMap((combo) => {
        // Check if totalPriceList exists and map through each entry
        return combo?.totalPriceList?.map((item) => {
          // Safely access TF value
          return item?.fd?.ADULT?.fC?.TF;
        }).filter(Boolean); // Remove any undefined values
      }).flat(); // Flatten the array to get a single list of all fares
  
      // Ensure that allFares is not empty before calculating min/max
      if (allFares.length > 0) {
        const minFare = Math.min(...allFares);
        const maxFare = Math.max(...allFares);
  
        // Update state with the minimum and maximum fares
        setMinFare(minFare);
        setMaxFare(maxFare);
        setSliderValue([minFare, maxFare]);
      }
    }
  }, [search]);

  // useEffect(() => {
  //   applyFilters();
  // }, [search, sliderValue, deptimeRange, arrtimeRange, airlines, checkedStops]);

  const parseSearchParams = (data) => {
    const searchData = {
      Segments: [{}, {}],
    };
    const params = decodeURIComponent(data).split("*");

    params.forEach((param) => {
      const [key, value] = param.split("_");
      switch (key) {
        case "dest":
          searchData.Segments[0].Destination = value;
          searchData.Segments[1].Origin = value;
          break;
        case "org":
          searchData.Segments[0].Origin = value;
          searchData.Segments[1].Destination = value;
          break;
        case "dep":
          searchData.Segments[0].PreferredDepartureTime = value;
          searchData.Segments[0].PreferredArrivalTime = value;
          break;
        case "arr":
          searchData.Segments[1].PreferredArrivalTime = value;
          searchData.Segments[1].PreferredDepartureTime = value;

          break;
        case "px":
          const [adultCount, childCount, infantCount] = value.split("-");
          searchData.AdultCount = adultCount;
          searchData.ChildCount = childCount;
          searchData.InfantCount = infantCount;
          break;
        case "jt":
          searchData.JourneyType = value;
          break;
        case "cbn":
          searchData.Segments[0].FlightCabinClass = value;
          searchData.Segments[1].FlightCabinClass = value;
          break;
        default:
          break;
      }
    });

    return searchData;
  };
  const [selectedOption, setSelectedOption] = useState(2);

  const handleOptionChange = (event) => {
    setSelectedOption(parseInt(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tripTypeMapping = {
      OneWay: 1,
      RoundTrip: 2,
      MultiCity: 3,
    };

    // const cabin = event.target.cabinClass.value;
    const tripType = tripTypeMapping[!active ? "RoundTrip" : "OneWay"];

    const SearchData = {
      EndUserIp: "192.168.10.10",
      AdultCount: rooms[0].adults,
      ChildCount: rooms[0].children,
      InfantCount: rooms[0].infants,
      JourneyType: tripType,
      Segments: [
        {
          Origin: event.target.from.value,
          Destination: event.target.to.value,
          FlightCabinClass: selectedOption,
          PreferredDepartureTime: startDate.startOf("day").format("YYYY-MM-DD"),
          PreferredArrivalTime: endDate
            ? endDate.startOf("day").format("YYYY-MM-DD")
            : startDate.add(1, "day").startOf("day").format("YYYY-MM-DD"),
        },
      ],
    };
    if (active) {
      window.location.assign(
        `/flightList/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
    }

    if (active2) {
      // if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
      window.location.assign(
        `/international-round/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
      // } else {
      //   window.location.assign(
      //     `/round/${encodeURIComponent(
      //       `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
      //     )}`
      //   );
      // }
    }
  };

  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992;

    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30);
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60);
    } else {
      setDateRangeConfig(2, 40, 50);
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize) => {
    setNumberOfMonths(numberOfMonths);
  };

  const isSameDay = (date1, date2) => date1.isSame(date2, "day");

  const renderDayContents = (day) => {
    // const fare = fares.find((item) => isSameDay(day, item.date));
    const isStartDate = startDate && isSameDay(day, startDate);
    const isEndDate = endDate && isSameDay(day, endDate);
    const isInRange =
      startDate && endDate && day.isBetween(startDate, endDate, "day", "[]");

    let classNames = ["DayPicker-Day"];
    if (isStartDate) classNames.push("DayPicker-Day--start");
    if (isEndDate) classNames.push("DayPicker-Day--end");
    if (isInRange) classNames.push("DayPicker-Day--range");

    return (
      <div className={classNames.join(" ")}>
        <span style={{ fontWeight: "600", fontSize: "13px" }}>
          {day.format("D")}
        </span>
        <br />
        {/* {fare && (
          <span
            style={{ fontSize: "10px", color: "#002543", fontWeight: "600" }}
          >
            {fare.fare}
          </span>
        )} */}
      </div>
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFocusedInput(null);
      setCalVisible(false);
    } else if (startDate) {
      setFocusedInput("endDate");
    }
  };

  const toggleCalendar = (input) => {
    setCalVisible(!calVisible);
    if (!calVisible) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        setCalVisible(!calVisible);
        if (!calVisible) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const updateRoomsData = (searchData) => {
    const updatedRooms = [
      {
        adults: parseInt(searchData.AdultCount),
        children: parseInt(searchData.ChildCount),
        infants: parseInt(searchData.InfantCount),
      },
    ];
    setRooms(updatedRooms);
  };

  const updateDates = (searchData) => {
    const departureTime = moment(searchData.Segments[0].PreferredDepartureTime);
    const arrivalTime = moment(searchData.Segments[1].PreferredArrivalTime);
    setStartDate(departureTime);
    // active2 &&
    setEndDate(arrivalTime);
  };

  // const handleClick = (id) => {
  //   setActiveId(activeId === id ? null : id);
  // };

  const [isItemSelected, setIsItemSelected] = useState(false);
  const [isItemSelected2, setIsItemSelected2] = useState(false);
  const [destination1, setDestination1] = useState("");
  const [destination2, setDestination2] = useState("");

  const handleCitySelect = (city) => {
    setDestinationCity(`${city["CITYNAME"]} (${city["AIRPORTCODE"]})`);
    setSelectedCityCode(city["AIRPORTCODE"]);
    SetClickDestination(false); // Close the city suggestion div
    setSearchInput(`${city["AIRPORTCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected(true);
    setDestination1(city);
  };

  const handleCitySelect2 = (city) => {
    setDestinationCity2(`${city["CITYNAME"]} (${city["AIRPORTCODE"]})`);
    setSelectedCityCode2(city["AIRPORTCODE"]);
    SetClickDestination2(false); // Close the city suggestion div
    setSearchInput2(`${city["AIRPORTCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected2(true);
    setDestination2(city);
  };

  const handleMoreFare = (idx) => {
    setShowModal(showModal === idx ? null : idx);
  };
  const handlebookmodal = (idx) => {
    navigate(`/flight-detail/${encodeURIComponent(idx)}/null/SrdvTJ`);
    setShowModal(false);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setVisible(scrollTop > 300);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleCheckedstops = (stopType) => {
    if (checkedStops.includes(stopType)) {
      setCheckedStops(checkedStops.filter((stop) => stop !== stopType));
    } else {
      setCheckedStops([...checkedStops, stopType]);
    }
  };

  // const handledepTimeFilter = (range) => {
  //   if (deptimeRange[0] === range[0] && deptimeRange[1] === range[1])
  //     setdepTimeRange([0, 0]);
  //   else setdepTimeRange(range);
  // };

  // const handlearrTimeFilter = (range) => {
  //   if (arrtimeRange[0] === range[0] && arrtimeRange[1] === range[1])
  //     setarrTimeRange([0, 0]);
  //   else setarrTimeRange(range);
  // };


  const handledepTimeFilter = (range) => {
    if (deptimeRange[0] === range[0] && deptimeRange[1] === range[1])
      setdepTimeRange([0, 0]);  // Clear the filter if the same range is selected
    else setdepTimeRange(range);
  };

  // Handle arrival time filter
  const handlearrTimeFilter = (range) => {
    if (arrtimeRange[0] === range[0] && arrtimeRange[1] === range[1])
      setarrTimeRange([0, 0]);  // Clear the filter if the same range is selected
    else setarrTimeRange(range);
  };




  // const applyFilters = () => {
  //   if (!search || isLoading) {
  //     return;
  //   }

  //   const selectedAirlines = airlines
  //     .filter((airline) => airline.selected)
  //     .map((airline) => airline.name);

  //   const newFilteredInboundData =
  //     search &&
  //     search.COMBO[0].
  //     totalPriceList
  //     .filter((e) => {
  //       const fareInRange =
  //         e.fd.fC.TF >= sliderValue[0] &&
  //         e.fd.fC.TF <= sliderValue[1];

  //       const depTimeInRange =
  //         (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
  //         (new Date(
  //           e.OriginDestinationOptions[0].FlightSegments[0].DepartureDateTime
  //         ).getHours() >= deptimeRange[0] &&
  //           new Date(
  //             e.OriginDestinationOptions[0].FlightSegments[0].DepartureDateTime
  //           ).getHours() <= deptimeRange[1]);

  //       const arrTimeInRange =
  //         (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
  //         (new Date(
  //           e.OriginDestinationOptions[0].FlightSegments[0].ArrivalDateTime
  //         ).getHours() >= arrtimeRange[0] &&
  //           new Date(
  //             e.OriginDestinationOptions[0].FlightSegments[0].ArrivalDateTime
  //           ).getHours() <= arrtimeRange[1]);

  //       const isAirlineSelected =
  //         selectedAirlines.length === 0 ||
  //         selectedAirlines.includes(
  //           e.OriginDestinationOptions[0].FlightSegments[0].OperatingAirline
  //             .EquipmentName
  //         );

  //       let stopCountMatch = false;
  //       if (checkedStops.length === 0) {
  //         stopCountMatch = true;
  //       } else {
  //         const stopCount =
  //           e.OriginDestinationOptions[0].FlightSegments.length - 1;
  //         if (checkedStops.includes("non-stop") && stopCount === 0) {
  //           stopCountMatch = true;
  //         } else if (checkedStops.includes("1-stop") && stopCount === 1) {
  //           stopCountMatch = true;
  //         } else if (checkedStops.includes("2-stop") && stopCount === 2) {
  //           stopCountMatch = true;
  //         } else if (checkedStops.includes("3-stop") && stopCount >= 3) {
  //           stopCountMatch = true;
  //         }
  //       }
  //       return (
  //         fareInRange &&
  //         depTimeInRange &&
  //         arrTimeInRange &&
  //         isAirlineSelected &&
  //         stopCountMatch
  //       );
  //     });

   
  //   setFilteredInboundData(newFilteredInboundData);
  //   console.log("newfilteredinbounddata", newFilteredInboundData);
  
  //   console.log("Filtered Inbound Data:", newFilteredInboundData);
 
    
  // };


  // const applyFilters = () => {
  //   if (!search || isLoading) {
  //     return;
  //   }

  //   // Extract selected airlines from the airlines array
  //   const selectedAirlines = airlines
  //     .filter((airline) => airline.selected)
  //     .map((airline) => airline.name); // name is the airline name

  //   // Filter the totalPriceList based on the selected filters
  //   const newFilteredInboundData = search.COMBO[0].totalPriceList.filter((e) => {
  //     // Check if fare is within the specified range (e.fd.fC.TF is the total fare)
  //     const fareInRange = e.fd.fC.TF >= sliderValue[0] && e.fd.fC.TF <= sliderValue[1];

  //     // Check if departure time is within the specified range (e.dt is the departure time)
  //     const depTimeInRange =
  //       (deptimeRange[0] === 0 && deptimeRange[1] === 0) || // No time range selected
  //       (new Date(e.dt).getHours() >= deptimeRange[0] &&
  //         new Date(e.dt).getHours() <= deptimeRange[1]);

  //     // Check if arrival time is within the specified range (e.at is the arrival time)
  //     const arrTimeInRange =
  //       (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) || // No time range selected
  //       (new Date(e.at).getHours() >= arrtimeRange[0] &&
  //         new Date(e.at).getHours() <= arrtimeRange[1]);

  //     // Filter based on airline selection (e.fD.aI.name for the airline name)
  //     const isAirlineSelected =
  //       selectedAirlines.length === 0 ||
  //       selectedAirlines.includes(e.fD.aI.name); // Check if the airline is selected

  //     // Check stop count (based on e.sI.stops)
  //     let stopCountMatch = false;
  //     if (checkedStops.length === 0) {
  //       stopCountMatch = true; // No stop filter selected, include all flights
  //     } else {
  //       const stopCount = e.sI.length; // Stops is based on length of sI array
  //       if (checkedStops.includes('non-stop') && stopCount === 1) {
  //         stopCountMatch = true; // Non-stop flights (1 segment)
  //       } else if (checkedStops.includes('1-stop') && stopCount === 2) {
  //         stopCountMatch = true; // 1-stop flights (2 segments)
  //       } else if (checkedStops.includes('2-stop') && stopCount === 3) {
  //         stopCountMatch = true; // 2-stop flights (3 segments)
  //       } else if (checkedStops.includes('3-stop') && stopCount >= 4) {
  //         stopCountMatch = true; // 3 or more stop flights
  //       }
  //     }

  //     // Return true if all filter conditions match
  //     return fareInRange && depTimeInRange && arrTimeInRange && isAirlineSelected && stopCountMatch;
  //   });

  //   // Set the filtered inbound data to state
  //   setFilteredInboundData(newFilteredInboundData);
  //   console.log("Filtered Inbound Data:", newFilteredInboundData);
  // };

  // Example usage of the `applyFilters` function
  // useEffect(() => {
  //   applyFilters();
  // }, [airlines, deptimeRange, arrtimeRange, sliderValue, checkedStops]);



















  const handleChecked = (airlineName) => {
    const updatedAirlines = airlines.map((airline) =>
      airline.name === airlineName
        ? { ...airline, selected: !airline.selected }
        : airline
    );
    setAirlines(updatedAirlines);
  };

  // const handleShowAllairlinenames = (event) => {
  //   const isChecked = event.target.checked;
  //   const updatedAirlines = airlines.map((airline) => ({
  //     ...airline,
  //     selected: isChecked,
  //   }));
  //   setAirlines(updatedAirlines);
  // };

  const handleShowAllairlinenames = (event) => {
    const isChecked = event.target.checked;
    
    // Set all airlines to selected or deselected based on the checkbox
    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: isChecked,
    }));
    
    setAirlines(updatedAirlines);
  };



  const handleShowAllStops = (event) => {
    if (event.target.checked) {
      setCheckedStops(["non-stop", "1-stop", "2-stop"]);
    } else {
      setCheckedStops([]);
    }
  };

  const clearAllFilters = () => {
    setSliderValue([minFare, maxFare]);
    setdepTimeRange([0, 0]);
    setarrTimeRange([0, 0]);
    setCheckedStops(["non-stop", "1-stop", "2-stop"]);

    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: true,
    }));
    setAirlines(updatedAirlines);
  };

  const [selectedFlight, setSelectedFlight] = useState(filteredInboundData[0]);
  const [selectedFlight2, setSelectedFlight2] = useState(
    filteredOutboundData && filteredOutboundData[0]
  );
  console.log("Selectet flighttsss", selectedFlight);

  const handleClickDetail = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const handleBookRound = () => {
    navigate(
      `/flight-detail/${encodeURIComponent(
        selectedFlight.ResultIndex
      )}/${encodeURIComponent(selectedFlight2.ResultIndex)}`
    );
  };

  console.log("filtered inbounddddd dataaaa", filteredInboundData);
  const [searchInput, setSearchInput] = useState(destinationCity);
  const [searchInput2, setSearchInput2] = useState(destinationCity2);
  const [cities2, setCities2] = useState([]);
  const [cities22, setCities22] = useState([]);
  const fetchDatas = (value) => {
    fetch("https://admin.lelotrip.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.CITYNAME &&
                user.CITYNAME.toLowerCase().includes(value.toLowerCase())) ||
                (user.AIRPORTNAME &&
                  user.AIRPORTNAME.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value.toLowerCase())) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())))
            );
          })
          .map((user) => {
            let priority = 3;

            if (
              user.AIRPORTCODE &&
              user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);

        console.log("RESULTS", results);
        setCities2(results);
      });
  };

  const handleInputChange = (value) => {
    setSearchInput(value.toUpperCase());
    fetchDatas(value);
    SetClickDestination(true);
    setIsItemSelected(false);
  };
  const fetchDatass = (value) => {
    fetch("https://admin.lelotrip.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.CITYNAME &&
                user.CITYNAME.toLowerCase().includes(value.toLowerCase())) ||
                (user.AIRPORTNAME &&
                  user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value.toLowerCase())) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(
                    value.toLowerCase()
                  )) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())))
            );
          })
          .map((user) => {
            let priority = 3;

            if (
              user.AIRPORTCODE &&
              user.AIRPORTCODE.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value.toLowerCase())
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);

        // console.log("RESULTS",results)
        setCities22(results);
      });
  };
  const handleInputChange2 = (value) => {
    setSearchInput2(value.toUpperCase());
    fetchDatass(value);
    SetClickDestination2(true);
    setIsItemSelected2(false);
  };

  const parseFareRule = (fareRuleDetail) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = fareRuleDetail;
    const listItems = Array.from(tempDiv.querySelectorAll("li")).map((li) =>
      li.textContent.trim()
    );

    return listItems;
  };

  const [fareRules, setFareRules] = useState("");
  const handleClick = async (id, SrdvIndex) => {
    setActiveId(activeId === id ? null : id);
    console.log("srdvIdx", SrdvIndex);
    if (SrdvIndex !== "SrdvP" && SrdvIndex !== "SrdvTJ") {
      console.log("tbo farerule called");
      const response1 = await axios.post(
        "https://admin.lelotrip.com/api/flight-fare-rule",
        {
          EndUserIp: "192.168.11.58",
          TokenId: token,
          TraceId: traceId,
          ResultIndex: id,
        }
      );
      console.log("response1", response1);
      setFareRules(
        response1.data.data.FareRules[0] &&
          parseFareRule(response1.data.data.FareRules[0].FareRuleDetail)
      );
    } else if (SrdvIndex === "SrdvTJ") {
      console.log("tj farerule called");
      const responseTJ1 = await axios.post(
        "https://admin.lelotrip.com/api/flight_farerule",
        {
          id: id,
          flowType: "SEARCH",
        }
      );
      console.log("response1", responseTJ1);
      if (responseTJ1.data.success)
        setFareRules(
          responseTJ1.data.data.fareRule && responseTJ1.data.data.fareRule
        );
    } else if (SrdvIndex === "SrdvP") {
      console.log("parto farerule called");
      const responseP1 = await axios.post(
        "https://admin.lelotrip.com/api/air_rules",
        {
          SessionId: sessionId,
          FareSourceCode: id,
        }
      );
      console.log("responseP1", responseP1);
      if (responseP1.data.success)
        setFareRules(
          responseP1.data.data.FareRules[0] &&
            responseP1.data.data.FareRules[0].RuleDetails[0].Rules
        );
    }
  };

  const [departureFlight, setDepartureFlight] = useState("");
  const [returnFlight, setReturnFlight] = useState("");

  useEffect(() => {
    // Ensure search is defined and has COMBO data
    if (search && search.COMBO && search.COMBO.length > 0) {
      // Assuming you're working with the first combo (adjust if needed)
      const combo = search.COMBO[0];

      // Filter flights for departure (isRs = false)
      const outboundFlights = combo.sI.filter((flight) => !flight.isRs);
      // Filter flights for return (isRs = true)
      const inboundFlights = combo.sI.filter((flight) => flight.isRs);

      // Set departure to the first outbound flight if available
      if (outboundFlights.length > 0) {
        setDepartureFlight(outboundFlights);
      }

      // Set return to the last inbound flight if available
      if (inboundFlights.length > 0) {
        setReturnFlight(inboundFlights);
      }
    }
  }, [search]); // This effect will run whenever 'search' changes

  return (
    <div className="flightListPage inRound">
      <div className="roundtrippg">
        <ReSearchForm
          dataSearch={dataSearch}
          handleSubmit={handleSubmit}
          tripsActive={tripsActive}
          active={active}
          active2={active2}
          active3={active3}
          handleSearchFlight={handleSearchFlight}
          handleSearchFlightRound={handleSearchFlightRound}
          handleSearchFlightMultiPle={handleSearchFlightMultiPle}
          setTripsActive={setTripsActive}
          searchInput={searchInput}
          searchInput2={searchInput2}
          SetClickDestination={SetClickDestination}
          SetClickDestination2={SetClickDestination2}
          isItemSelected={isItemSelected}
          isItemSelected2={isItemSelected2}
          handleInputChange={handleInputChange}
          handleInputChange2={handleInputChange2}
          filteredCities={cities2}
          filteredCities2={cities22}
          clickDestination={clickDestination}
          clickDestination2={clickDestination2}
          handleCitySelect={handleCitySelect}
          handleCitySelect2={handleCitySelect2}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          toggleCalendar={toggleCalendar}
          rooms={rooms}
          setTravellerActive={setTravellerActive}
          travellerActive={travellerActive}
          updateRoom={updateRoom}
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          calVisible={calVisible}
          setCalVisible={setCalVisible}
          handleDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          renderDayContents={renderDayContents}
          numberOfMonths={numberOfMonths}
        />
      </div>

      <div className="flightsMainBody">
        {/* <Navbar /> */}
        {/* <Bookingmenu /> */}
        <Container className="bodyDiv">
          <Row>
            <FilterBar
              showFilter={showFilter}
              minFare={minFare}
              maxFare={maxFare}
              sliderValue={sliderValue}
              clearAllFilters={clearAllFilters}
              handleSliderChange={handleSliderChange}
              handledepTimeFilter={handledepTimeFilter}
              deptimeRange={deptimeRange}
              arrtimeRange={arrtimeRange}
              handlearrTimeFilter={handlearrTimeFilter}
              handleShowAllStops={handleShowAllStops}
              checkedStops={checkedStops}
              handleCheckedstops={handleCheckedstops}
              handleShowAllairlinenames={handleShowAllairlinenames}
              airlines={airlines}
              handleChecked={handleChecked}
              setShowFilter={setShowFilter}
              // applyFilters={applyFilters}
              handleChnageCurrency={handleChnageCurrency}
              handleChangeCurrency2={handleChangeCurrency2}
            />
            <Col md={9} className="rightDiv" style={{ paddingRight: "0px" }}>
              <Row style={{ margin: "0px", padding: "0px" }} className="mbsm">
                <div className="calendarSlider" style={{ padding: "0px" }}>
                  <div className="sliderLeft" style={{ width: "100%" }}>
                    <img
                      src={leftArrow}
                      alt=""
                      style={{
                        height: "80%",
                        paddingBlock: "15px",
                        paddingInline: "15px",
                        borderLeft: "1px solid rgb(228,228,228)",
                      }}
                    />
                    <div style={{}} className="datesdiv">
                      {sliderItems.map((item, index) => (
                        <CalenderSliderContent
                          key={index}
                          style={{ textWrap: "nowrap" }}
                        >
                          <p
                            //   style={{
                            //     lineHeight: "0cm",
                            //     paddingTop: "5px",
                            //     fontSize: "15px",
                            //     marginTop: "10px",
                            //   }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              paddingInline: "5px",
                              marginBottom: "2px",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                            className="calenderslidercontent"
                          >
                            {item.date}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "black",
                              marginBottom: "2px",
                              color: "rgb(45, 50, 144)",
                              fontWeight: "600",
                            }}
                            className="calenderprice"
                          >
                            ₹{item.price}
                          </p>
                          <div className="calenderData">
                            {/* <img src={rupee} alt="" /> */}
                            {/* <p>{item.price}</p> */}
                          </div>
                        </CalenderSliderContent>
                      ))}
                    </div>
                    <img
                      src={rightArrow}
                      alt=""
                      style={{
                        height: "80%",
                        paddingBlock: "15px",
                        paddingInline: "15px",
                        borderLeft: "1px solid rgb(228,228,228)",
                      }}
                    />
                  </div>
                  {/* <div className="sliderRight" style={{ width: "10%" }}>
                    <MdFlight size={22} className="" />
                  </div> */}
                </div>
              </Row>

              {/* <Row
                style={{ margin: "0px", padding: "0px" }}
                className="d-none d-md-flex"
              >
                <div
                  className="menuItems"
                  style={{
                    width: "100%",
                    fontWeight: "600",
                    color: "rgb(45, 50, 144)",
                    margin: "10px 0px",
                    padding: "5px",
                    background: "rgb(249, 249, 249)",
                    height: "max-content",
                    textAlign: "center",
                  }}
                >
                  <p style={{ marginLeft: "3px" }}>AIRLINES</p>
                  <p>DEPART</p>
                  <p>DURATION</p>
                  <p>ARRIVE</p>
                  <p>PRICE</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>RECOMMENDED</p>
                    <label
                      className="toggleSwitch"
                      style={{
                        marginTop: "0px",
                        marginLeft: "5px",
                        marginRight: "2px",
                      }}
                    >
                      <input className="toggleInput" type="checkbox" />
                      <span className="toggleSlider" />
                    </label>
                  </div>
                </div>
              </Row> */}
              {search.COMBO && search.COMBO.length !== 0 && !isLoading ? (
                <div>
                  {search.COMBO &&
                    search.COMBO.map((e) => {
                      return (
                        <InternationalFlightListCard
                          e={e}
                          handleMoreFare={handleMoreFare}
                          handleClick={handleClick}
                          activeId={activeId}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          // departureFlight={departureFlight}
                          // returnFlight={returnFlight}
                          formatTime={formatTime}
                          handlebookmodal={handlebookmodal}
                          handleChnageCurrency={handleChnageCurrency}
                          handleChangeCurrency2={handleChangeCurrency2}
                          fareRules={fareRules}
                        />
                      );
                    })}
                </div>
              ) : (
                <div className="d-flex flex-column gap-3">
                  <FlightListSkeleton />
                  <FlightListSkeleton />
                  <FlightListSkeleton />
                  <FlightListSkeleton />
                </div>
              )}
            </Col>
          </Row>
          <div
            className={`applyFilter ${Visible && "show"}`}
            onClick={() => setShowFilter(true)}
          >
            <FaFilter /> Filter
          </div>
        </Container>
      </div>
    </div>
  );
};

export default FlightInternational;
