// import  from "./FlightDetails.module.css";
// import './FlightDetails.css'
// import "./FlightListInfo.css";
// import axios from "axios";
import React, { useState } from "react";
import Box from "@mui/material/Box";
// import { } from '@mui/material/Tab';
// import { TabContext } from '@mui/lab/TabContext';
// import { TabList } from '@mui/lab/TabList';
// import { TabPanel } from '@mui/lab/TabPanel';
import flightDetails1 from "../FlightList/images/flightDetails1.PNG";
import arrowForward from "../FlightList/images/arrowForward.png";
import clock from "../FlightList/images/clock.png";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import { Col, Container, Row } from "react-bootstrap";
import { HiMiniHandThumbUp } from "react-icons/hi2";
import { FaRegThumbsUp } from "react-icons/fa";

export const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}-${dateNum} ${month} ${year}`;
};

export const FlightListInfoInternational = ({
  idx,
  flight,
  handleChnageCurrency,
  handleChangeCurrency2,
  isInterNational,
  fareRules,
  SrdvIndex,
  departureFlight,
  returnFlight,
}) => {
  const [value, setValue] = useState("1");
  console.log("farerules3", fareRules);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLayoverTime = (arrivalTime, nextDepartureTime) => {
    const arrival = new Date(arrivalTime);
    const nextDeparture = new Date(nextDepartureTime);
    const layoverMinutes = Math.floor(
      (nextDeparture.getTime() - arrival.getTime()) / (1000 * 60)
    );
    const layoverHours = Math.floor(layoverMinutes / 60);
    const layoverMinutesRemainder = layoverMinutes % 60;
    return `${layoverHours}h : ${layoverMinutesRemainder}m`;
  };

  const formatPolicyInfo = (policyInfo) => {
    if (policyInfo.startsWith("__nls__")) {
      policyInfo = policyInfo.slice(7); // Remove the initial __nls__
    }
    return policyInfo.replace(/__nls__/g, "\n");
  };

  const renderFareRulesForSrdvTJ = () => {
    if (!fareRules) return null;
    return Object.keys(fareRules).map((route, index) => {
      const rules = fareRules[route];
      return (
        <div
          key={index}
          className="m-info-tips2 mar20"
          style={{ display: "block" }}
        >
          <div
            className="left_gtk"
            style={{
              maxHeight: "300px",
              overflow: "auto",
              scrollbarWidth: "thin",
              paddingInline: "15px",
            }}
          >
            <div key={index}>
              <h3>{route}</h3>
              <table className="fare-rule-table" style={{ minWidth: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Type</th>
                    <th style={{ width: "15%" }}>Amount</th>
                    <th style={{ width: "55%" }}>Policy Info</th>
                  </tr>
                </thead>
                <tbody>
                  {rules.fr && rules.fr.DATECHANGE && (
                    <>
                      <tr>
                        <td>Date Change</td>
                        <td>{rules.fr.DATECHANGE.DEFAULT.amount}</td>
                        <td>
                          {formatPolicyInfo(
                            rules.fr.DATECHANGE.DEFAULT.policyInfo
                          )
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </td>
                      </tr>
                      {/* {renderFareComponents(rules.fr.DATECHANGE.DEFAULT.fcs)} */}
                    </>
                  )}

                  {rules.fr && rules.fr.CANCELLATION && (
                    <>
                      <tr>
                        <td>CANCELLATION</td>
                        <td>{rules.fr.CANCELLATION.DEFAULT.amount}</td>
                        <td>
                          {formatPolicyInfo(
                            rules.fr.CANCELLATION.DEFAULT.policyInfo
                          )
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </td>
                      </tr>
                      {/* {renderFareComponents(rules.fr.DATECHANGE.DEFAULT.fcs)} */}
                    </>
                  )}

                  {rules.fr && rules.fr.NO_SHOW && (
                    <>
                      <tr>
                        <td>NO SHOW</td>
                        <td>{rules.fr.NO_SHOW.DEFAULT.amount}</td>
                        <td>
                          {formatPolicyInfo(rules.fr.NO_SHOW.DEFAULT.policyInfo)
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </td>
                      </tr>
                      {/* {renderFareComponents(rules.fr.DATECHANGE.DEFAULT.fcs)} */}
                    </>
                  )}

                  {rules.fr && rules.fr.SEAT_CHARGEABLE && (
                    <>
                      <tr>
                        <td>SEAT CHARGEABLE</td>
                        <td>{rules.fr.SEAT_CHARGEABLE.DEFAULT.amount}</td>
                        <td>
                          {formatPolicyInfo(
                            rules.fr.SEAT_CHARGEABLE.DEFAULT.policyInfo
                          )
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </td>
                      </tr>
                      {/* {renderFareComponents(rules.fr.DATECHANGE.DEFAULT.fcs)} */}
                    </>
                  )}
                  {/* Add more rows for other rule types as needed */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {/* {idx === flight.FareSourceCode && !isInterNational ? (
        <div className="detailDiv">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Flight Information"
                    value="1"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      fontSize: "12px",
                    }}
                    className="tabFlightData"
                  />
                  <Tab
                    label="Fare Details And Rules"
                    value="2"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "25px",
                      fontSize: "12px",
                    }}
                  />
                  <Tab
                    label="Baggage Information"
                    value="3"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  />
                  <Tab
                    label="Cancellation And Change Rules"
                    value="4"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: "5px" }}>
                <div className="block-content-2 custom_block_content">
                  <div className="box-result custom_box_result">
                    <div className="flight_tags depart_tags">
                      <span style={{ zIndex: 2 }}>Departure</span>
                    </div>
                    {flight.Segments[0].map((e, indexx) => (
                      <div key={indexx} style={{ marginBlock: "10px" }}>
                        <div className="FlightInfoDetailsMain">
                          <p className="flightInfoDetailsPara">
                            {e.Origin.Airport.AirportCode} →{" "}
                            {e.Destination.Airport.AirportCode}
                          </p>
                          <img
                            src={arrowForward}
                            alt=""
                            className="flightInfoImageMain"
                          />
                          <p className="flightInfoDetailsPara">
                            {e.Airline.AirlineCode}-{e.Airline.FlightNumber}
                          </p>
                        </div>
                        <div className="flightInforDetailsMain2">
                          <div
                            className="imgdiv"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "15%",
                            }}
                          >
                            <img
                              src={`/Images/AirlineLogo/${e.Airline.AirlineCode}.gif`}
                              alt=""
                              className="flightInfoImagetag"
                            />
                            <div className="flightInfoDivtag1">
                              <p
                                className="flightInfoDivPara1"
                                style={{ margin: "0px" }}
                              >
                                {e.Airline.AirlineName}
                              </p>
                              <p className="flightInfoPara4">
                                {e.Airline.FlightNumber}
                              </p>
                            </div>
                          </div>
                          <div className="flightInfoMainDiv2">
                            <p className="flightInfoPara2">
                              {new Date(e.Origin.DepTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )}
                            </p>
                            <p className="flightInfoPara3">
                              {e.Origin.Airport.CityName}{" "}
                              {new Date(e.Origin.DepTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.Origin.DepTime)}
                            </p>
                            {e.Origin.Airport.Terminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.Origin.Airport.Terminal}
                              </p>
                            )}
                          </div>
                          <div className="flightInfoMainDiv5">
                            <img
                              src={clock}
                              alt=""
                              className="flightInfoImage2"
                            />
                            <p className="flightInfoPara12">{e.Duration} m</p>
                          </div>
                          <div
                            className="flightInfoMainDiv2"
                           
                          >
                            <p className="flightInfoPara2">
                              {" "}
                              {new Date(
                                e.Destination.ArrTime
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })}
                            </p>
                            <p className="flightInfoPara3">
                              {" "}
                              {e.Destination.Airport.CityName}{" "}
                              {new Date(
                                e.Destination.ArrTime
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.Destination.ArrTime)}
                            </p>
                            {e.Destination.Airport.Terminal && (
                              <p className="flightInfoPara4">
                                Terminal - {e.Destination.Airport.Terminal}
                              </p>
                            )}
                          </div>
                        </div>
                        {indexx < flight.Segments[0].length - 1 && (
                        
                          <div
                            className="layover_time"
                            style={{ marginBlock: "20px" }}
                          >
                            <div className="layover_txt">
                              Layover:
                              <span>
                                {formatLayoverTime(
                                  e.Destination.ArrTime,
                                  flight.Segments[0][indexx + 1].Origin.DepTime
                                )}
                              </span>
                            </div>
                          </div>
                          
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {flight.Segments[1] && (
                  <div className="block-content-2 custom_block_content">
                    <div className="box-result custom_box_result">
                      <div className="flight_tags depart_tags">
                        <span style={{ zIndex: 2 }}>Return</span>
                      </div>
                      {flight.Segments[1] &&
                        flight.Segments[1].map((e, indexx) => (
                          <div key={indexx} style={{ marginBlock: "10px" }}>
                            <div className="FlightInfoDetailsMain">
                              <p className="flightInfoDetailsPara">
                                {e.Origin.Airport.AirportCode} →{" "}
                                {e.Destination.Airport.AirportCode}
                              </p>
                              <img
                                src={arrowForward}
                                alt=""
                                className="flightInfoImageMain"
                              />
                              <p className="flightInfoDetailsPara">
                                {e.Airline.AirlineCode}-{e.Airline.FlightNumber}
                              </p>
                            </div>
                            <div className="flightInforDetailsMain2">
                              <div
                                className="imgdiv"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  width: "15%",
                                }}
                              >
                                <img
                                  src={`/Images/AirlineLogo/${e.Airline.AirlineCode}.gif`}
                                  alt=""
                                  className="flightInfoImagetag"
                                />
                                <div className="flightInfoDivtag1">
                                  <p
                                    className="flightInfoDivPara1"
                                    style={{ margin: "0px" }}
                                  >
                                    {e.Airline.AirlineName}
                                  </p>
                                  <p className="flightInfoPara4">
                                    {e.Airline.FlightNumber}
                                  </p>
                                </div>
                              </div>
                              <div className="flightInfoMainDiv2">
                                <p className="flightInfoPara2">
                                  {new Date(
                                    e.Origin.DepTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  })}
                                </p>
                                <p className="flightInfoPara3">
                                  {e.Origin.Airport.CityName}{" "}
                                  {new Date(
                                    e.Origin.DepTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                                <p className="flightInfoPara4">
                                  {formatTime(e.Origin.DepTime)}
                                </p>
                                {e.Origin.Airport.Terminal && (
                                  <p className="flightInfoPara4">
                                    Terminal - {e.Origin.Airport.Terminal}
                                  </p>
                                )}
                              </div>
                              <div className="flightInfoMainDiv5">
                                <img
                                  src={clock}
                                  alt=""
                                  className="flightInfoImage2"
                                />
                                <p className="flightInfoPara12">
                                  {e.Duration} m
                                </p>
                              </div>
                              <div
                                className="flightInfoMainDiv2"
                               
                              >
                                <p className="flightInfoPara2">
                                  {" "}
                                  {new Date(
                                    e.Destination.ArrTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: false,
                                  })}
                                </p>
                                <p className="flightInfoPara3">
                                  {" "}
                                  {e.Destination.Airport.CityName}{" "}
                                  {new Date(
                                    e.Destination.ArrTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                                <p className="flightInfoPara4">
                                  {formatTime(e.Destination.ArrTime)}
                                </p>
                                {e.Destination.Airport.Terminal && (
                                  <p className="flightInfoPara4">
                                    Terminal - {e.Destination.Airport.Terminal}
                                  </p>
                                )}
                              </div>
                            </div>
                            {indexx < flight.Segments[1].length - 1 && (
                             
                              <div
                                className="layover_time"
                                style={{ marginBlock: "20px" }}
                              >
                                <div className="layover_txt">
                                  Layover:
                                  <span>
                                    {formatLayoverTime(
                                      e.Destination.ArrTime,
                                      flight.Segments[1][indexx + 1].Origin
                                        .DepTime
                                    )}
                                  </span>
                                </div>
                              </div>
                        
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </TabPanel>
              <TabPanel value="2">
                <div className="flightInfoMainDiv6 d-flex">
                  <div className="flightInfoInnerDiv6">
                    <div
                      className="flightInfoInnerInnerDiv"
                      style={{ border: "none", flexWrap: "wrap" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Adult</p>
                        <p>{flight.FareBreakdown[0].PassengerCount}</p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Base Fare</p>
                        <p>
                        ₹
                          {
                            flight.FareBreakdown[0].BaseFare
                          }
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Tax</p>
                        <p>
                        ₹{flight.FareBreakdown[0].Tax}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Total</p>
                        <b>
                        ₹
                          {
                            flight.FareBreakdown[0].BaseFare +
                              flight.FareBreakdown[0].Tax
                        }
                        </b>
                      </div>
                    </div>
                    {flight.FareBreakdown[1] && (
                      <div
                        className="flightInfoInnerInnerDiv"
                        style={{ border: "", flexWrap: "wrap" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Child</p>
                          <p>{flight.FareBreakdown[1].PassengerCount}</p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Base Fare</p>
                          <p>
                          ₹
                            {
                              flight.FareBreakdown[1].BaseFare
                            }
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Tax</p>
                          <p>
                          ₹{flight.FareBreakdown[1].Tax}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Total</p>
                          <b>
                          ₹
                            {
                              flight.FareBreakdown[1].BaseFare +
                                flight.FareBreakdown[1].Tax
                          }
                          </b>
                        </div>
                      </div>
                    )}
                    {flight.FareBreakdown[2] && (
                      <div
                        className="flightInfoInnerInnerDiv"
                        style={{ border: "", flexWrap: "wrap" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Infant</p>
                          <p>{flight.FareBreakdown[2].PassengerCount}</p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Base Fare</p>
                          <p>
                          ₹
                            {
                              flight.FareBreakdown[2].BaseFare
                            }
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Tax</p>
                          <p>
                          ₹{flight.FareBreakdown[2].Tax}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Total</p>
                          <b>
                          ₹
                            {
                              flight.FareBreakdown[2].BaseFare +
                                flight.FareBreakdown[2].Tax
                            }
                          </b>
                        </div>
                      </div>
                    )}
                    <div className="flightInfoMainInner1">
                      <p className="flightInfoPara8">Total (Base Fare)</p>
                      <p className="flightInfoPara9">
                     ₹
                        {flight.Fare.BaseFare}
                      </p>
                    </div>
                    <div className="flightInfoMainDivInner4">
                      <p className="flightInfoPar10">Total Tax</p>
                      <p className="flightInfoPara9">
                        ₹
                        {flight.Fare.Tax}
                      </p>
                    </div>
                    <div className="flightInfoMainInner1">
                      <p className="flightInfoPara8">Total (Fee + Surcharge)</p>
                      <p className="flightInfoPara9">
                       ₹
                        {flight.Fare.PublishedFare}
                      </p>
                    </div>
                  </div>
                  <div className="flightInfoMainDiv15">
                    <h3>Terms & Conditions: </h3>
                    <div>
                      {SrdvIndex === "SrdvTJ" && (
                        <div>{renderFareRulesForSrdvTJ()}</div>
                      )}

                      {SrdvIndex === "SrdvP" && (
                        <div
                          className="left_gtk"
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            paddingRight: "10px",
                          }}
                        >
                          <div>
                            <div
                              dangerouslySetInnerHTML={{ __html: fareRules }}
                            />
                          </div>
                        </div>
                      )}

                      {SrdvIndex !== "SrdvP" &&
                        SrdvIndex !== "SrdvTJ" &&
                        fareRules && (
                          <ul>
                            {SrdvIndex !== "SrdvP" &&
                              SrdvIndex !== "SrdvTJ" &&
                              fareRules &&
                              fareRules.map((item, index) => (
                                <li key={index} className="m-info-tips2_item">
                                  <p className="txt-sb">{item}</p>
                                </li>
                              ))}
                          </ul>
                        )}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
               

                <div className="flightInfortabPanel1">
                  <Row
                    style={{
                      margin: "0px",
                      borderBottom: "1px solid #80808036",
                      alignItems: "center",
                    }}
                  >
                  
                    <Col>
                     
                      <p>Airline</p>
                     
                    </Col>
                    <Col>
               
                      <p>Check-in Baggage</p>
                   
                    </Col>
                    <Col>
                     
                      <p>Cabin Baggage</p>
                    
                    </Col>
                 
                  </Row>
                  <Row
                    style={{
                      margin: "0px",
                      marginBottom: "5px",
                      border: "1px solid #80808036",
                      alignItems: "center",
                    }}
                  >
                 
                    <Col>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "5px",
                          padding: "5px",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <img
                          src="https://airhex.com/images/airline-logos/indigo.png"
                          alt=""
                          className="flightInfoPanelImage"
                        />
                        <div className="flightInfoPara9">
                          <p style={{ fontSize: "13px" }}>
                            {flight.Segments[0][0].Airline.AirlineName}
                          </p>
                          <p style={{ fontSize: "12px", lineHeight: "0cm" }}>
                            {flight.Segments[0][0].Airline.FlightNumber}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                     
                      <p>{flight.Segments[0][0].Baggage}</p>
                    
                    </Col>
                    <Col>
                    
                      <p>{flight.Segments[0][0].CabinBaggage}</p>
                      
                    </Col>
 
                  </Row>
                  <div className="flightInfoPanel9">
                    <ul className="flightInfoPanel0">
                      <li className="flightInforUnderList">
                        <HiMiniHandThumbUp className="icons" />
                        Baggage information mentioned above is obtained from
                        airline's reservation system, LeloTrip does not
                        guarantee the accuracy of this information.
                      </li>
                      <li className="flightInforUnderList">
                        <HiMiniHandThumbUp className="icons" />
                        The baggage allowance may vary according to stop-overs,
                        connecting flights. changes in airline rules. etc.
                      </li>
                    </ul>
                  </div>
                </div>
             
              </TabPanel>
              <TabPanel value="4">
                <div className="flightInfoMainDiv8" style={{ padding: "10px" }}>
                  <h3 className="flightInfoPara9">Terms & Conditions</h3>
                 
                  {SrdvIndex === "SrdvTJ" && (
                        <div>{renderFareRulesForSrdvTJ()}</div>
                      )}

                      {SrdvIndex === "SrdvP" && (
                        <div
                          className="left_gtk"
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            paddingRight: "10px",
                          }}
                        >
                          <div>
                            <div
                              dangerouslySetInnerHTML={{ __html: fareRules }}
                            />
                          </div>
                        </div>
                      )}

                      {SrdvIndex !== "SrdvP" &&
                        SrdvIndex !== "SrdvTJ" &&
                        fareRules && (
                          <div>
                          <ul>
                            {SrdvIndex !== "SrdvP" &&
                              SrdvIndex !== "SrdvTJ" &&
                              fareRules &&
                              fareRules.map((item, index) => (
                                <li key={index} className="m-info-tips2_item">
                                  <p className="txt-sb">{item}</p>
                                </li>
                              ))}
                          </ul>
                          </div>
                        )}
                  
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      ) : null} */}
      {flight ? (
        <div className="detailDiv">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Flight Information"
                    value="1"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      fontSize: "12px",
                    }}
                    className="tabFlightData"
                  />
                  <Tab
                    label="Fare Details And Rules"
                    value="2"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "25px",
                      fontSize: "12px",
                    }}
                  />
                  <Tab
                    label="Baggage Information"
                    value="3"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  />
                  <Tab
                    label="Cancellation And Change Rules"
                    value="4"
                    sx={{
                      fontSize: "16px",
                      paddingRight: "15px",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="1" style={{ padding: "5px" }}>
                <div className="block-content-2 custom_block_content">
                  <div className="box-result custom_box_result">
                    <div className="flight_tags depart_tags">
                      <span style={{ zIndex: 2 }}>Departure</span>
                    </div>
                    {departureFlight.map((e, indexx) => (
                      <div key={indexx} style={{ marginBlock: "10px" }}>
                        <div className="FlightInfoDetailsMain">
                          <p className="flightInfoDetailsPara">
                            {e.fD.aI.code} → {e.fD.fN}
                          </p>
                          <img
                            src={arrowForward}
                            alt=""
                            className="flightInfoImageMain"
                          />
                          {/* <p className="flightInfoDetailsPara">
                            {e.OperatingAirline.Code}-{e.OperatingAirline.FlightNumber}
                          </p> */}
                        </div>
                        <div className="flightInforDetailsMain2">
                          <div
                            className="imgdiv"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "15%",
                            }}
                          >
                            <img
                              src={`/Images/AirlineLogo/${e.fD.aI.code}.gif`}
                              alt=""
                              className="flightInfoImagetag"
                            />
                            <div className="flightInfoDivtag1">
                              <p
                                className="flightInfoDivPara1"
                                style={{ margin: "0px" }}
                              >
                                {/* {e.OperatingAirline.EquipmentName} */}
                              </p>
                              <p className="flightInfoPara4">
                                {/* {e.OperatingAirline.FlightNumber} */}
                              </p>
                            </div>
                          </div>
                          <div className="flightInfoMainDiv2">
                            <p className="flightInfoPara2">
                              {e.da.cityCode}({e.da.city})
                              {/* {new Date(e.dt).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                }
                              )} */}
                            </p>
                            <p className="flightInfoPara3">
                              {/* {e.Origin.Airport.CityName}{" "} */}
                              {new Date(e.dt).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.dt)}
                            </p>
                            {/* {e.Origin.Airport.Terminal && ( */}
                            <p className="flightInfoPara4">{e.da.name}</p>
                            <p
                              className="flightInfoPara4"
                              style={{ width: "60%" }}
                            >
                              {e.da.terminal}
                            </p>
                            {/* )} */}
                          </div>
                          <div className="flightInfoMainDiv5">
                            <img
                              src={clock}
                              alt=""
                              className="flightInfoImage2"
                            />
                            <p className="flightInfoPara12">{e.duration} m</p>
                          </div>
                          <div
                            className="flightInfoMainDiv2"
                            //  style={{ marginLeft: "180px", paddingTop: "0px" }}
                          >
                            <p className="flightInfoPara2">
                              {" "}
                              {e.aa.cityCode}({e.aa.city})
                              {/* {new Date(
                                e.at
                              ).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })} */}
                            </p>
                            <p className="flightInfoPara3">
                              {" "}
                              {/* {e.aa.city}{" "} */}
                              {new Date(e.at).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </p>
                            <p className="flightInfoPara4">
                              {formatTime(e.at)}
                            </p>
                            {/* {e.Destination.Airport.Terminal && ( */}
                            <p className="flightInfoPara4">{e.aa.name}</p>
                            <p
                              className="flightInfoPara4"
                              style={{ width: "60%" }}
                            >
                              {e.aa.terminal}
                            </p>
                            {/* )} */}
                          </div>
                        </div>
                        {indexx < departureFlight.length - 1 && (
                          <div
                            className="layover_time"
                            style={{ marginBlock: "20px" }}
                          >
                            <div className="layover_txt">
                              Layover:
                              <span>
                                {formatLayoverTime(
                                  e.at,
                                  departureFlight[indexx + 1].dt
                                )}
                              </span>
                            </div>
                          </div>
                          // </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {returnFlight && (
                  <div className="block-content-2 custom_block_content">
                    <div className="box-result custom_box_result">
                      <div className="flight_tags depart_tags">
                        <span style={{ zIndex: 2 }}>Return</span>
                      </div>
                      {returnFlight &&
                        returnFlight.map((e, indexx) => (
                          <div key={indexx} style={{ marginBlock: "10px" }}>
                            <div className="FlightInfoDetailsMain">
                              <p className="flightInfoDetailsPara">
                                {e.fD.aI.code} → {e.fD.fN}
                              </p>
                              <img
                                src={arrowForward}
                                alt=""
                                className="flightInfoImageMain"
                              />
                              <p className="flightInfoDetailsPara">
                                {/* {e.OperatingAirline.Code}-{e.OperatingAirline.FlightNumber} */}
                              </p>
                            </div>
                            <div className="flightInforDetailsMain2">
                              <div
                                className="imgdiv"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  width: "15%",
                                }}
                              >
                                <img
                                  src={`/Images/AirlineLogo/${e.fD.aI.code}.gif`}
                                  alt=""
                                  className="flightInfoImagetag"
                                />
                                <div className="flightInfoDivtag1">
                                  <p
                                    className="flightInfoDivPara1"
                                    style={{ margin: "0px" }}
                                  >
                                    {/* {e.OperatingAirline.EquipmentName} */}
                                  </p>
                                  <p className="flightInfoPara4">
                                    {/* {e.OperatingAirline.FlightNumber} */}
                                  </p>
                                </div>
                              </div>
                              <div className="flightInfoMainDiv2">
                                <p className="flightInfoPara2">
                                  {e.da.cityCode}({e.da.city})
                                </p>
                                <p className="flightInfoPara3">
                                  {new Date(e.dt).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                                <p className="flightInfoPara4">
                                  {formatTime(e.dt)}
                                </p>
                                <p className="flightInfoPara4">{e.da.name}</p>
                                <p
                                  className="flightInfoPara4"
                                  style={{ width: "60%" }}
                                >
                                  {e.da.terminal}
                                </p>
                              </div>
                              <div className="flightInfoMainDiv5">
                                <img
                                  src={clock}
                                  alt=""
                                  className="flightInfoImage2"
                                />
                                <p className="flightInfoPara12">
                                  {e.duration} m
                                </p>
                              </div>
                              <div className="flightInfoMainDiv2">
                                <p className="flightInfoPara2">
                                  {" "}
                                  {e.aa.cityCode}({e.aa.city})
                                </p>
                                <p className="flightInfoPara3">
                                  {" "}
                                  {new Date(e.at).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </p>
                                <p className="flightInfoPara4">
                                  {formatTime(e.at)}
                                </p>
                                <p className="flightInfoPara4">{e.aa.name}</p>
                                <p
                                  className="flightInfoPara4"
                                  style={{ width: "60%" }}
                                >
                                  {e.aa.terminal}
                                </p>
                              </div>
                            </div>
                            {indexx < returnFlight.length - 1 && (
                              <div
                                className="layover_time"
                                style={{ marginBlock: "20px" }}
                              >
                                <div className="layover_txt">
                                  Layover:
                                  <span>
                                    {formatLayoverTime(
                                      e.at,
                                      returnFlight[indexx + 1].dt
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </TabPanel>
              {/* <TabPanel value="2">
                <div className="flightInfoMainDiv6 d-flex">
                  <div className="flightInfoInnerDiv6">
                    <div
                      className="flightInfoInnerInnerDiv"
                      style={{ border: "none", flexWrap: "wrap" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Adult</p>
                        <p>{flight.AirItineraryPricingInfo.PtcFareBreakdown[0].PassengerTypeQuantity.Quantity}</p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Base Fare</p>
                        <p>
                        ₹
                          {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[0].PassengerFare.BaseFare
                          }
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Tax</p>
                        <p>
                        ₹{flight.AirItineraryPricingInfo.PtcFareBreakdown[0].PassengerFare.Taxes[0].Amount}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="flightInfoPara7">Total</p>
                        <b>
                        ₹
                          {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[0].PassengerFare.TotalFare
                        }
                        </b>
                      </div>
                    </div>
                    {flight.AirItineraryPricingInfo.PtcFareBreakdown[1] && (
                      <div
                        className="flightInfoInnerInnerDiv"
                        style={{ border: "", flexWrap: "wrap" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Child</p>
                          <p>{flight.AirItineraryPricingInfo.PtcFareBreakdown[1].PassengerTypeQuantity.Quantity}</p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Base Fare</p>
                          <p>
                          ₹
                            {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[1].PassengerFare.BaseFare
                          }
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Tax</p>
                          <p>
                          ₹{flight.AirItineraryPricingInfo.PtcFareBreakdown[1].PassengerFare.Taxes[0].Amount}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Total</p>
                          <b>
                          ₹
                            {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[1].PassengerFare.TotalFare
                          }
                          </b>
                        </div>
                      </div>
                    )}
                    {flight.AirItineraryPricingInfo.PtcFareBreakdown[2] && (
                      <div
                        className="flightInfoInnerInnerDiv"
                        style={{ border: "", flexWrap: "wrap" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Infant</p>
                          <p>{flight.AirItineraryPricingInfo.PtcFareBreakdown[2].PassengerTypeQuantity.Quantity}</p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Base Fare</p>
                          <p>
                          ₹
                            {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[2].PassengerFare.BaseFare
                          }
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Tax</p>
                          <p>
                          ₹{flight.AirItineraryPricingInfo.PtcFareBreakdown[2].PassengerFare.Taxes[0].Amount}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="flightInfoPara7">Total</p>
                          <b>
                          ₹
                            {
                            flight.AirItineraryPricingInfo.PtcFareBreakdown[2].PassengerFare.TotalFare
                          }
                          </b>
                        </div>
                      </div>
                    )}
                    <div className="flightInfoMainInner1">
                      <p className="flightInfoPara8">Total (Base Fare)</p>
                      <p className="flightInfoPara9">
                       ₹
                        {
                        flight.AirItineraryPricingInfo.ItinTotalFare.BaseFare}
                      </p>
                    </div>
                    <div className="flightInfoMainDivInner4">
                      <p className="flightInfoPar10">Total Tax</p>
                      <p className="flightInfoPara9">
                        ₹
                        {flight.AirItineraryPricingInfo.ItinTotalFare.TotalTax}
                      </p>
                    </div>
                    <div className="flightInfoMainInner1">
                      <p className="flightInfoPara8">Total (Fee + Surcharge)</p>
                      <p className="flightInfoPara9">
                       ₹
                        {flight.AirItineraryPricingInfo.ItinTotalFare.TotalFare}
                      </p>
                    </div>
                  </div>
                  <div className="flightInfoMainDiv15">
                    <h3>Terms & Conditions: </h3>
                    <div>
                      {SrdvIndex === "SrdvTJ" && (
                        <div>{renderFareRulesForSrdvTJ()}</div>
                      )}

                      {SrdvIndex === "SrdvP" && (
                        <div
                          className="left_gtk"
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            paddingRight: "10px",
                          }}
                        >
                          <div>
                            <div
                              dangerouslySetInnerHTML={{ __html: fareRules }}
                            />
                          </div>
                        </div>
                      )}

                      {SrdvIndex !== "SrdvP" &&
                        SrdvIndex !== "SrdvTJ" &&
                        fareRules && (
                          <ul>
                            {SrdvIndex !== "SrdvP" &&
                              SrdvIndex !== "SrdvTJ" &&
                              fareRules &&
                              fareRules.map((item, index) => (
                                <li key={index} className="m-info-tips2_item">
                                  <p className="txt-sb">{item}</p>
                                </li>
                              ))}
                          </ul>
                        )}
                    </div>
                  </div>
                </div>
              </TabPanel> */}
              <TabPanel value="3">
               

                <div className="flightInfortabPanel1">
                  <Row
                    style={{
                      margin: "0px",
                      borderBottom: "1px solid #80808036",
                      alignItems: "center",
                    }}
                  >
                  
                    <Col>
                   
                      <p>Airline</p>
                    
                    </Col>
                    <Col>
                   
                      <p>Check-in Baggage</p>
                     
                    </Col>
                    <Col>
                    
                      <p>Cabin Baggage</p>
                    
                    </Col>
              
                  </Row>
                  <Row
                    style={{
                      margin: "0px",
                      marginBottom: "5px",
                      border: "1px solid #80808036",
                      alignItems: "center",
                    }}
                  >
                   
                    <Col>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "5px",
                          padding: "5px",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <img
                          src="https://airhex.com/images/airline-logos/indigo.png"
                          alt=""
                          className="flightInfoPanelImage"
                        />
                        <div className="flightInfoPara9">
                          <p style={{ fontSize: "13px" }}>
                            {flight.sI[0].fD.aI.code}-  {flight.sI[0].fD.fN}
                          </p>
                          <p style={{ fontSize: "12px", lineHeight: "0cm" }}>
                           
                            {/* 
                            {flight.OriginDestinationOptions[0].FlightSegments[0].OperatingAirline.FlightNumber} */}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                  <p> {flight && flight.totalPriceList[0].fd.ADULT.bI.iB }</p>
                     
                    
                    </Col>
                    <Col>
                    <p>   {flight && flight.totalPriceList[0].fd.ADULT.bI.cB}</p>
                    </Col>

           
                  </Row>
                  <div className="flightInfoPanel9">
                    <ul className="flightInfoPanel0">
                      <li className="flightInforUnderList">
                        <HiMiniHandThumbUp className="icons" />
                        Baggage information mentioned above is obtained from
                        airline's reservation system, LeloTrip does not
                        guarantee the accuracy of this information.
                      </li>
                      <li className="flightInforUnderList">
                        <HiMiniHandThumbUp className="icons" />
                        The baggage allowance may vary according to stop-overs,
                        connecting flights. changes in airline rules. etc.
                      </li>
                    </ul>
                  </div>
                </div>
              
              </TabPanel>
              {/* <TabPanel value="4">
                <div className="flightInfoMainDiv8" style={{ padding: "10px" }}>
                  <h3 className="flightInfoPara9">Terms & Conditions</h3>
                 
                  {SrdvIndex === "SrdvTJ" && (
                        <div>{renderFareRulesForSrdvTJ()}</div>
                      )}

                      {SrdvIndex === "SrdvP" && (
                        <div
                          className="left_gtk"
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            paddingRight: "10px",
                          }}
                        >
                          <div>
                            <div
                              dangerouslySetInnerHTML={{ __html: fareRules }}
                            />
                          </div>
                        </div>
                      )}

                      {SrdvIndex !== "SrdvP" &&
                        SrdvIndex !== "SrdvTJ" &&
                        fareRules && (
                          <div>
                          <ul>
                            {SrdvIndex !== "SrdvP" &&
                              SrdvIndex !== "SrdvTJ" &&
                              fareRules &&
                              fareRules.map((item, index) => (
                                <li key={index} className="m-info-tips2_item">
                                  <p className="txt-sb">{item}</p>
                                </li>
                              ))}
                          </ul>
                          </div>
                        )}
                  
                </div>
              </TabPanel> */}
            </TabContext>
          </Box>
        </div>
      ) : null}
    </>
  );
};
