import React, { useEffect, useState } from "react";
import "./FlightDetail.css";
import FlightDetailSkeleton from "./FlightDetailSkeleton";
// import FlightDetailSide from "./FlightDetailSide";
import DepatureDetail from "./DepatureDetail";
import FlightPayModal from "./FlightPayModal";
// import { MdFlightLand } from "react-icons/md";
// import { FaAngleDown, FaRegThumbsUp } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  Container,
  Row,
  Col,
  // Form,
  // InputGroup,
  // FormControl,
  Nav,
  // Modal,
  // Tab,
  // Card,
} from "react-bootstrap";
// import { HiPlusSmall } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { HiMiniMinusSmall } from "react-icons/hi2";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import GoodToKnow from "./GoodToKnow";
// import FlightPayDetail from "./FlightPayDetail";
import FareRule from "./FareRule";
import SSRContent from "./SSRContent";
import ChargesOneWay from "./ChargesOneWay";
import BDSend from "./BDSend";
import TravellerInformation from "./TravellerInformation";
import EmiModal from "./EmiModal";
import { cities12 } from "../../../Cities";
import { initializePhonePe, PhonePe } from "phonepesdk-web"; // Import PhonePe SDK

const FlightDetail = () => {
  const [fromCurrency, setFromCurrency] = useState("INR");
  const [toCurrency, setToCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(null);

  const [fromCurrency2, setFromCurrency2] = useState("IRR");
  const [toCurrency2, setToCurrency2] = useState("INR");
  const [exchangeRate2, setExchangeRate2] = useState(null);

  const [isPassportMandatory, setIsPassportMandatory] = useState(false);
  const [openPayBtn, setOpenPayBtn] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState(false);
  const decodedIndex = decodeURIComponent(useParams().index);
  const decodedIndex2 = decodeURIComponent(useParams().index2);
  const srdvIdx = decodeURIComponent(useParams().srdvIdx);
  const [flight, setFlight] = useState(null);
  const [fareRule, setFareRule] = useState(null);
  const [ssrResponse, setSsrResponse] = useState(null);
  const [flight2, setFlight2] = useState(null);
  const [fareRule2, setFareRule2] = useState(null);
  const [ssrResponse2, setSsrResponse2] = useState(null);
  const [emiBtn, setEmiBtn] = useState(false);
  const search = useSelector((state) => state.flight.search);
  const [passengerBaggagePreferences, setPassengerBaggagePreferences] =
    useState([]);
  const [passengerSeatPreferences, setPassengerSeatPreferences] = useState([]);
  const [passengerMealPreferences, setPassengerMealPreferences] = useState([]);
  const [token, setToken] = useState();
  const [sessionId, setSessionId] = useState();
  const [traceId, setTraceId] = useState();
  const navigate = useNavigate();
  const [showdetail, setShowdetail] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [passengers, setPassengers] = useState([]);
  const [formData, setFormData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [infant, setInfant] = useState([]);
  const { walletData } = useSelector((state) => state.auth);
  // const [promoCode, setPromoCode] = useState("");
  // const [appliedCode, setAppliedCode] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");
  console.log("passenger seat preference", passengerSeatPreferences);
  console.log("passenger meal preference", passengerMealPreferences);
  console.log("passenger baggage preference", passengerBaggagePreferences);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const URL = `https://admin.lelotrip.com/api/currency_convert/${fromCurrency}/${toCurrency}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        // console.log("ratetetesafdfs", rate);
        setExchangeRate(rate);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };
    if (fromCurrency !== toCurrency) {
      getExchangeRate(URL);
    } else {
      setExchangeRate(1);
    }
  }, []);
  useEffect(() => {
    const URL = `https://admin.lelotrip.com/api/currency_convert/${fromCurrency2}/${toCurrency2}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        console.log("rate IRR to INR", rate);
        setExchangeRate2(rate);
      } catch (error) {
        console.error("Error fetching exchange rate2:", error);
      }
    };
    if (fromCurrency2 !== toCurrency2) {
      getExchangeRate(URL);
    } else {
      setExchangeRate2(1);
    }
  }, []);
  useEffect(() => {
    const storedTraceId = String(sessionStorage.getItem("traceId"));
    const storedToken = String(localStorage.getItem("token"));
    const storedSessionId = String(localStorage.getItem("sessionId"));

    if (storedTraceId && storedToken && storedSessionId) {
      setTraceId(storedTraceId);
      setToken(storedToken);
      setSessionId(storedSessionId);
    } else {
      setTraceId(undefined);
      setToken(undefined);
      setSessionId(undefined);
    }

    const fetchFlightDetailsTBO = async () => {
      try {
        const response1 = await axios.post(
          "https://admin.lelotrip.com/api/flight-fare-rule",
          {
            EndUserIp: "192.168.11.58",
            TokenId: token,
            TraceId: traceId,
            ResultIndex: decodedIndex,
          }
        );
        console.log("FareRule1 and FareQuote1 Request", {
          EndUserIp: "192.168.11.58",
          TokenId: token,
          TraceId: traceId,
          ResultIndex: decodedIndex,
        });
        // let response12;
        // if (
        //   decodedIndex2 !== "" &&
        //   decodedIndex2 !== "undefined" &&
        //   decodedIndex2 !== "null"
        // ) {
        //   response12 = await axios.post(
        //     "https://admin.lelotrip.com/api/flight-fare-rule",
        //     {
        //       EndUserIp: "192.168.11.58",
        //       TokenId: token,
        //       TraceId: traceId,
        //       ResultIndex: decodedIndex2,
        //     }
        //   );
        //   console.log("FareRule2 and FareQuote2 Request", {
        //     EndUserIp: "192.168.11.58",
        //     TokenId: token,
        //     TraceId: traceId,
        //     ResultIndex: decodedIndex2,
        //   });
        // }
        const response2 = await axios.post(
          "https://admin.lelotrip.com/api/flight-fare-quote",
          {
            EndUserIp: "192.168.11.58",
            TokenId: token,
            TraceId: traceId,
            ResultIndex: decodedIndex,
          }
        );
        // let response22;
        // if (
        //   decodedIndex2 !== "" &&
        //   decodedIndex2 !== "undefined" &&
        //   decodedIndex2 !== "null"
        // ) {
        //   response22 = await axios.post(
        //     "https://admin.lelotrip.com/api/flight-fare-quote",
        //     {
        //       EndUserIp: "192.168.11.58",
        //       TokenId: token,
        //       TraceId: traceId,
        //       ResultIndex: decodedIndex2,
        //     }
        //   );
        // }

        console.log("response1", response1);
        console.log("response2", response2);
        // console.log("response12", response12);
        // console.log("response22", response22);

        setFlight(response2.data.data.Results);
        setFareRule(
          response1.data.data.FareRules[0] &&
            parseFareRule(response1.data.data.FareRules[0].FareRuleDetail)
        );

        // response22 && setFlight2(response22.data.data.Results);
        // response12 &&
        //   setFareRule2(
        //     response12.data.data.FareRules[0] &&
        //       parseFareRule(response12.data.data.FareRules[0].FareRuleDetail)
        //   );

        const newPassengers =
          response2.data.data.Results &&
          response2.data.data.Results.FareBreakdown.flatMap(
            ({ PassengerType, PassengerCount }) => {
              const typeLabel =
                PassengerType === 1
                  ? "Adult"
                  : PassengerType === 2
                  ? "Child"
                  : "Infant";
              return Array.from({ length: PassengerCount }, (_, index) => ({
                id: index + 1,
                type: typeLabel,
                name: `Passenger ${index + 1}`,
              }));
            }
          );

        setPassengers(newPassengers);
        console.log("newPassenger", newPassengers);
        if (
          !response1.data.success ||
          !response2.data.success ||
          (decodedIndex2 !== "" &&
            decodedIndex2 !== "undefined" &&
            decodedIndex2 !== "null")
          //  &&
          // (!response12.data.success || !response22.data.success)
        ) {
          navigate("/404");
        }
        fetchSSRTBO();
      } catch (error) {
        console.error("Error fetching flight data:", error);
      }
    };
    const fetchSSRTBO = async () => {
      try {
        let response3;
        response3 = await axios.post(
          "https://admin.lelotrip.com/api/flightSsr-lcc",
          {
            EndUserIp: "192.168.11.58",
            TokenId: token,
            TraceId: traceId,
            ResultIndex: decodedIndex,
          }
        );

        let response32;
        if (
          decodedIndex2 !== "" &&
          decodedIndex2 !== "undefined" &&
          decodedIndex2 !== "null"
        ) {
          response32 = await axios.post(
            "https://admin.lelotrip.com/api/flightSsr-lcc",
            {
              EndUserIp: "192.168.11.58",
              TokenId: token,
              TraceId: traceId,
              ResultIndex: decodedIndex2,
            }
          );
        }
        console.log("response3", response3);
        console.log("response32", response32);

        if (response3 && response3.data.success) {
          setSsrResponse(response3.data.data);
        }

        if (response32 && response32.data.success) {
          setSsrResponse2(response32.data.data);
        }
      } catch (error) {
        console.error("Error fetching flight data:", error);
      }
    };
    const fetchFlightDetailsSrdvTJ = async () => {
      console.log("TJ requestdata", {
        id: decodedIndex,
        flowType: "SEARCH",
      });
      try {
        const responseTJ = await axios.post(
          "https://admin.lelotrip.com/api/flight_review",
          {
            priceIds: [decodedIndex],
          }
        );

        console.log("responseTJ", responseTJ);

        if (responseTJ.data.success) {
          setFlight(responseTJ.data.data);
          sessionStorage.setItem("BookingIdTJ", responseTJ.data.data.bookingId);
        }

        const responseTJ2 = await axios.post(
          "https://admin.lelotrip.com/api/flight_farerule",
          {
            id: sessionStorage.getItem("BookingIdTJ"),
            flowType: "REVIEW",
          }
        );

        console.log("responseTJ2", responseTJ2);

        if (responseTJ2.data.success)
          setFareRule(
            responseTJ2.data.data.fareRule && responseTJ2.data.data.fareRule
          );

        const newPassengers = Object.entries(
          responseTJ.data.data.searchQuery.paxInfo
        ).flatMap(([type, quantity]) => {
          const typeLabel =
            type === "ADULT" ? "Adult" : type === "CHILD" ? "Child" : "Infant";
          return Array.from({ length: quantity }, (_, index) => ({
            id: index + 1,
            type: typeLabel,
            name: `Passenger ${index + 1}`,
          }));
        });
        setPassengers(newPassengers);

        if (!responseTJ.data.success || !responseTJ2.data.success) {
          // navigate("/404");
        }
        // fetchSSRTJ();
        let response3;
        if (responseTJ.data.data && responseTJ.data.data.conditions.isa) {
          response3 = await axios.post(
            "https://admin.lelotrip.com/api/flight_seat",
            {
              bookingId: sessionStorage.getItem("BookingIdTJ"),
            }
          );
        }

        // let response32;
        // if (
        //   decodedIndex2 !== "" &&
        //   decodedIndex2 !== "undefined" &&
        //   decodedIndex2 !== "null"
        // ) {
        //   response32 = await axios.post(
        //     "https://admin.lelotrip.com/api/flightSsr-lcc",
        //     {
        //       EndUserIp: "192.168.11.58",
        //       TokenId: token,
        //       TraceId: traceId,
        //       ResultIndex: decodedIndex2,
        //     }
        //   );
        // }
        console.log("responseTJ3", response3);
        // console.log("response32", response32);
        const baggageMealDetails = responseTJ.data.data.tripInfos;

        if (response3 && response3.data.success) {
          // Add baggage and meal details to the seat map data
          const updatedSsrResponse = {
            ...response3.data.data,
            baggageMeal: baggageMealDetails,
            // meal: mealDetails,
          };
          setSsrResponse(updatedSsrResponse);
        } else {
          const updatedSsrResponse = {
            baggageMeal: baggageMealDetails,
            // meal: mealDetails,
          };
          setSsrResponse(updatedSsrResponse);
        }

        // if (response32 && response32.data.success) {
        //   setSsrResponse2(response32.data.data);
        // }
      } catch (error) {
        console.error("Error fetching SrdvTJ flight data:", error);
        // navigate("/404");
      }
    };

    // const fetchSSRTJ = async () => {
    // try {
    //   let response3;
    //   if(flight && flight.conditions.isa){
    //     response3 = await axios.post(
    //       "https://admin.lelotrip.com/api/flight_seat",
    //       {
    //         bookingId: sessionStorage.getItem("BookingIdTJ"),
    //       }
    //     );
    //   }

    //   // let response32;
    //   // if (
    //   //   decodedIndex2 !== "" &&
    //   //   decodedIndex2 !== "undefined" &&
    //   //   decodedIndex2 !== "null"
    //   // ) {
    //   //   response32 = await axios.post(
    //   //     "https://admin.lelotrip.com/api/flightSsr-lcc",
    //   //     {
    //   //       EndUserIp: "192.168.11.58",
    //   //       TokenId: token,
    //   //       TraceId: traceId,
    //   //       ResultIndex: decodedIndex2,
    //   //     }
    //   //   );
    //   // }
    //   console.log("responseTJ3", response3);
    //   // console.log("response32", response32);

    //   if (response3 && response3.data.success) {
    //     setSsrResponse(response3.data.data);
    //   }

    //   // if (response32 && response32.data.success) {
    //   //   setSsrResponse2(response32.data.data);
    //   // }
    // } catch (error) {
    //   console.error("Error fetching flight data:", error);
    // }
    // };
    const fetchFlightDetailsSrdvP = async () => {
      try {
        console.log("PArto Request data for details", {
          SessionId: sessionId,
          FareSourceCode: decodedIndex,
        });

        const responseP = await axios.post(
          "https://admin.lelotrip.com/api/fares",
          {
            SessionId: sessionId,
            FareSourceCode: decodedIndex,
          }
        );

        const responseP2 = await axios.post(
          "https://admin.lelotrip.com/api/air_rules",
          {
            SessionId: sessionId,
            FareSourceCode: decodedIndex,
          }
        );

        console.log("responseP", responseP);
        console.log("responseP2", responseP2);

        if (responseP2.data.success)
          setFareRule(
            responseP2.data.data.FareRules[0] &&
              responseP2.data.data.FareRules[0].RuleDetails[0].Rules
          );

        if (responseP.data.success) {
          const originDestinationOptions =
            responseP.data.data.PricedItinerary.OriginDestinationOptions;

          originDestinationOptions.forEach((option) => {
            option.FlightSegments.forEach((segment) => {
              const departureCode = segment.DepartureAirportLocationCode;
              const arrivalCode = segment.ArrivalAirportLocationCode;

              const departureCity = cities12.find(
                (city) => city.AIRPORTCODE === departureCode
              );
              const arrivalCity = cities12.find(
                (city) => city.AIRPORTCODE === arrivalCode
              );

              segment.DepartureCity = departureCity;
              segment.ArrivalCity = arrivalCity;
            });
          });

          setFlight(responseP.data.data);

          const newPassengers =
            responseP.data.success &&
            responseP.data.data.PricedItinerary.AirItineraryPricingInfo.PtcFareBreakdown.sort(
              (a, b) => {
                // Sorting based on PassengerType: Adult (1), Child (2), Infant (3)
                return (
                  a.PassengerTypeQuantity.PassengerType -
                  b.PassengerTypeQuantity.PassengerType
                );
              }
            ).flatMap(
              ({ PassengerTypeQuantity: { PassengerType, Quantity } }) => {
                const typeLabel =
                  PassengerType === 1
                    ? "Adult"
                    : PassengerType === 2
                    ? "Child"
                    : "Infant";
                return Array.from({ length: Quantity }, (_, index) => ({
                  id: index + 1,
                  type: typeLabel,
                  name: `Passenger ${index + 1}`,
                }));
              }
            );

          setPassengers(newPassengers);
        }

        if (!responseP.data.success || !responseP2.data.success) {
          // navigate("/404");
        }
      } catch (error) {
        console.error("Error fetching SrdvP flight data:", error);
        // navigate("/404");
      }
    };
    const fetchFlightDetailsSrdvTJround = async () => {
      console.log("TJ round requestdata", {
        priceIds: [decodedIndex, decodedIndex2],
        // flowType: "SEARCH",
      });
      try {
        const responseTJ = await axios.post(
          "https://admin.lelotrip.com/api/flight_review",
          {
            priceIds: [decodedIndex, decodedIndex2],
          }
        );

        console.log("responseTJround", responseTJ);

        if (responseTJ.data.success) {
          setFlight(responseTJ.data.data);
          sessionStorage.setItem("BookingIdTJ", responseTJ.data.data.bookingId);
        }

        const responseTJ2 = await axios.post(
          "https://admin.lelotrip.com/api/flight_farerule",
          {
            id: sessionStorage.getItem("BookingIdTJ"),
            flowType: "REVIEW",
          }
        );

        console.log("responseTJ2round", responseTJ2);

        if (responseTJ2.data.success)
          setFareRule2(
            responseTJ2.data.data.fareRule && responseTJ2.data.data.fareRule
          );

        const newPassengers = Object.entries(
          responseTJ.data.data.searchQuery.paxInfo
        ).flatMap(([type, quantity]) => {
          const typeLabel =
            type === "ADULT" ? "Adult" : type === "CHILD" ? "Child" : "Infant";
          return Array.from({ length: quantity }, (_, index) => ({
            id: index + 1,
            type: typeLabel,
            name: `Passenger ${index + 1}`,
          }));
        });
        setPassengers(newPassengers);

        if (!responseTJ.data.success || !responseTJ2.data.success) {
          // navigate("/404");
        }
        let response3;
        if (responseTJ.data.data && responseTJ.data.data.conditions.isa) {
          response3 = await axios.post(
            "https://admin.lelotrip.com/api/flight_seat",
            {
              bookingId: sessionStorage.getItem("BookingIdTJ"),
            }
          );
        }

        // let response32;
        // if (
        //   decodedIndex2 !== "" &&
        //   decodedIndex2 !== "undefined" &&
        //   decodedIndex2 !== "null"
        // ) {
        //   response32 = await axios.post(
        //     "https://admin.lelotrip.com/api/flightSsr-lcc",
        //     {
        //       EndUserIp: "192.168.11.58",
        //       TokenId: token,
        //       TraceId: traceId,
        //       ResultIndex: decodedIndex2,
        //     }
        //   );
        // }
        console.log("responseTJ3", response3);
        // console.log("response32", response32);
        const baggageMealDetails = responseTJ.data.data.tripInfos;

        if (response3 && response3.data.success) {
          // Add baggage and meal details to the seat map data
          const updatedSsrResponse = {
            ...response3.data.data,
            baggageMeal: baggageMealDetails,
            // meal: mealDetails,
          };
          setSsrResponse(updatedSsrResponse);
        } else {
          const updatedSsrResponse = {
            baggageMeal: baggageMealDetails,
            // meal: mealDetails,
          };
          setSsrResponse(updatedSsrResponse);
        }

        // if (response32 && response32.data.success) {
        //   setSsrResponse2(response32.data.data);
        // }
      } catch (error) {
        console.error("Error fetching SrdvP flight data:", error);
        // navigate("/404");
      }
    };
    if (
      decodedIndex2 !== "" &&
      decodedIndex2 !== "undefined" &&
      decodedIndex2 !== "null"
    ) {
      fetchFlightDetailsSrdvTJround();
    } else if (srdvIdx === "SrdvTJ") {
      fetchFlightDetailsSrdvTJ();
    } else if (srdvIdx === "SrdvP") {
      fetchFlightDetailsSrdvP();
    } else {
      fetchFlightDetailsTBO();
    }
  }, [decodedIndex, token, traceId, srdvIdx, decodedIndex2]);
  const handleChangeCurrency = (amount) => {
    if (!isNaN(amount) && exchangeRate) {
      // const convertedValue = amount * exchangeRate;
      // return convertedValue.toFixed(2);
      return amount;
    }
  };
  const handleChangeCurrency2 = (amount) => {
    if (!isNaN(amount) && exchangeRate2) {
      // const convertedValue = amount * exchangeRate2;
      // return convertedValue.toFixed(2);
      return amount;
    }
  };
  const parseFareRule = (fareRuleDetail) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = fareRuleDetail;
    const listItems = Array.from(tempDiv.querySelectorAll("li")).map((li) =>
      li.textContent.trim()
    );

    return listItems;
  };
  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");
    const list = [...formData];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setFormData(list);
    // console.log("adultdata", formData);
  };
  const handleInputChange1 = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");

    const list = [...childData];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setChildData(list);
  };

  const isValidExpiryDate = (index, date) => {
    const flightArrTime = new Date(
      flight.PricedItinerary.OriginDestinationOptions[
        flight.PricedItinerary.OriginDestinationOptions.length - 1
      ].FlightSegments[
        flight.PricedItinerary.OriginDestinationOptions[
          flight.PricedItinerary.OriginDestinationOptions.length - 1
        ].FlightSegments.length - 1
      ].ArrivalDateTime
    );

    const expiryDate = new Date(date);

    const sixMonthsAfterArrTime = new Date(
      flightArrTime.setMonth(flightArrTime.getMonth() + 6)
    );

    return expiryDate >= sixMonthsAfterArrTime;
  };

  const handleExpiryDateChange = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange(index, e);
    }
  };

  const handleExpiryDateChange1 = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange1(index, e);
    }
  };

  const handleExpiryDateChange2 = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange2(index, e);
    }
  };

  const isValidDateOfBirth = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 12;
  };

  const isValidDateOfBirth1 = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 2 && age < 12;
  };

  const isValidDateOfBirth2 = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age < 2;
  };

  const handleDOBChange = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth(value)) {
      alert("The age must be greater than 12 years.");
    } else {
      handleInputChange(index, e);
    }
  };

  const handleDOBChange1 = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth1(value)) {
      alert("The age must be between 2 to 12 years.");
    } else {
      handleInputChange1(index, e);
    }
  };

  const handleDOBChange2 = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth2(value)) {
      alert("The age must be less than 2 years.");
    } else {
      handleInputChange2(index, e);
    }
  };

  const handleInputChange2 = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");

    const list = [...infant];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setInfant(list);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}T00:00:00`;
  };

  const handleTicketBook = () => {
    setLoading(true);
    let isFirstAdult = true;

    const adultPassengers = formData.map((data, index) => {
      const isLeadPax = isFirstAdult;
      isFirstAdult = false;
      return {
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 1,
        DateOfBirth: formatDate(data.date),
        Gender: data.title.toLowerCase() === "mr" ? 1 : 2,
        PassportNo: data.passport,
        PassportExpiry: data.expirydate,
        PassportIssueDate: data.issuedate,
        AddressLine1: "123, Test",
        Fare: {
          Currency: flight.FareBreakdown[0].Currency,
          BaseFare: flight.FareBreakdown[0].BaseFare,
          Tax: flight.FareBreakdown[0].Tax,
          TaxBreakup: flight.FareBreakdown[0].TaxBreakup,
          YQTax: flight.FareBreakdown[0].YQTax,
          AdditionalTxnFeeOfrd: flight.FareBreakdown[0].AdditionalTxnFeeOfrd,
          AdditionalTxnFeePub: flight.FareBreakdown[0].AdditionalTxnFeePub,
          PGCharge: flight.FareBreakdown[0].PGCharge,
          OtherCharges: flight.Fare.OtherCharges,
          ChargeBU: flight.Fare.ChargeBU,
          Discount: flight.Fare.Discount,
          PublishedFare: flight.Fare.PublishedFare,
          CommissionEarned: flight.Fare.CommissionEarned,
          PLBEarned: flight.Fare.PLBEarned,
          IncentiveEarned: flight.Fare.IncentiveEarned,
          OfferedFare: flight.Fare.OfferedFare,
          TdsOnCommission: flight.Fare.TdsOnCommission,
          TdsOnPLB: flight.Fare.TdsOnPLB,
          TdsOnIncentive: flight.Fare.TdsOnIncentive,
          ServiceFee: flight.Fare.ServiceFee,
          TotalBaggageCharges: flight.Fare.TotalBaggageCharges,
          TotalMealCharges: flight.Fare.TotalMealCharges,
          TotalSeatCharges: flight.Fare.TotalSeatCharges,
          TotalSpecialServiceCharges: flight.Fare.TotalSpecialServiceCharges,
        },
        City: "Gurgaon",
        CountryCode: "IN",
        CountryName: "India",
        Nationality: "IN",
        ContactNo: "9879879877",
        Email: "harsh@tbtq.in",
        IsLeadPax: isLeadPax,
        // FFAirlineCode: flight.Fare.Segments[0][0].Airline.FFAirlineCode,
        // FFNumber: "123",
        GSTCompanyAddress: "",
        GSTCompanyContactNumber: "",
        GSTCompanyName: "",
        GSTNumber: "",
        GSTCompanyEmail: "",
      };
    });

    const childPassengers = childData.map((data) => ({
      Title: data.title,
      FirstName: data.firstName,
      LastName: data.lastName,
      PaxType: 2,
      DateOfBirth: formatDate(data.date),
      Gender: data.title.toLowerCase() === "mr" ? 1 : 2,
      PassportNo: data.passport,
      PassportExpiry: data.expirydate,
      PassportIssueDate: data.issuedate,
      AddressLine1: "123, Test",
      Fare: {
        Currency: flight.FareBreakdown[1].Currency,
        BaseFare: flight.FareBreakdown[1].BaseFare,
        Tax: flight.FareBreakdown[1].Tax,
        TaxBreakup: flight.FareBreakdown[1].TaxBreakup,
        YQTax: flight.FareBreakdown[1].YQTax,
        AdditionalTxnFeeOfrd: flight.FareBreakdown[1].AdditionalTxnFeeOfrd,
        AdditionalTxnFeePub: flight.FareBreakdown[1].AdditionalTxnFeePub,
        PGCharge: flight.FareBreakdown[1].PGCharge,
        OtherCharges: flight.Fare.OtherCharges,
        ChargeBU: flight.Fare.ChargeBU,
        Discount: flight.Fare.Discount,
        PublishedFare: flight.Fare.PublishedFare,
        CommissionEarned: flight.Fare.CommissionEarned,
        PLBEarned: flight.Fare.PLBEarned,
        IncentiveEarned: flight.Fare.IncentiveEarned,
        OfferedFare: flight.Fare.OfferedFare,
        TdsOnCommission: flight.Fare.TdsOnCommission,
        TdsOnPLB: flight.Fare.TdsOnPLB,
        TdsOnIncentive: flight.Fare.TdsOnIncentive,
        ServiceFee: flight.Fare.ServiceFee,
        TotalBaggageCharges: flight.Fare.TotalBaggageCharges,
        TotalMealCharges: flight.Fare.TotalMealCharges,
        TotalSeatCharges: flight.Fare.TotalSeatCharges,
        TotalSpecialServiceCharges: flight.Fare.TotalSpecialServiceCharges,
      },
      City: "Gurgaon",
      CountryCode: "IN",
      CountryName: "India",
      Nationality: "IN",
      ContactNo: "9879879877",
      Email: "harsh@tbtq.in",
      IsLeadPax: false,
      // FFAirlineCode: flight.Fare.Segments[0][0].Airline.FFAirlineCode,
      // FFNumber: "123",
      GSTCompanyAddress: "",
      GSTCompanyContactNumber: "",
      GSTCompanyName: "",
      GSTNumber: "",
      GSTCompanyEmail: "",
    }));

    const infantPassengers = infant.map((data) => ({
      Title: data.title,
      FirstName: data.firstName,
      LastName: data.lastName,
      PaxType: 3,
      DateOfBirth: formatDate(data.date),
      Gender: data.title.toLowerCase() === "mr" ? 1 : 2,
      PassportNo: data.passport,
      PassportExpiry: data.expirydate,
      PassportIssueDate: data.issuedate,
      AddressLine1: "123, Test",
      Fare: {
        Currency: flight.FareBreakdown[2].Currency,
        BaseFare: flight.FareBreakdown[2].BaseFare,
        Tax: flight.FareBreakdown[2].Tax,
        TaxBreakup: flight.FareBreakdown[2].TaxBreakup,
        YQTax: flight.FareBreakdown[2].YQTax,
        AdditionalTxnFeeOfrd: flight.FareBreakdown[2].AdditionalTxnFeeOfrd,
        AdditionalTxnFeePub: flight.FareBreakdown[2].AdditionalTxnFeePub,
        PGCharge: flight.FareBreakdown[2].PGCharge,
        OtherCharges: flight.Fare.OtherCharges,
        ChargeBU: flight.Fare.ChargeBU,
        Discount: flight.Fare.Discount,
        PublishedFare: flight.Fare.PublishedFare,
        CommissionEarned: flight.Fare.CommissionEarned,
        PLBEarned: flight.Fare.PLBEarned,
        IncentiveEarned: flight.Fare.IncentiveEarned,
        OfferedFare: flight.Fare.OfferedFare,
        TdsOnCommission: flight.Fare.TdsOnCommission,
        TdsOnPLB: flight.Fare.TdsOnPLB,
        TdsOnIncentive: flight.Fare.TdsOnIncentive,
        ServiceFee: flight.Fare.ServiceFee,
        TotalBaggageCharges: flight.Fare.TotalBaggageCharges,
        TotalMealCharges: flight.Fare.TotalMealCharges,
        TotalSeatCharges: flight.Fare.TotalSeatCharges,
        TotalSpecialServiceCharges: flight.Fare.TotalSpecialServiceCharges,
      },
      City: "Gurgaon",
      CountryCode: "IN",
      CountryName: "India",
      Nationality: "IN",
      ContactNo: "9879879877",
      Email: "harsh@tbtq.in",
      IsLeadPax: false,
      // FFAirlineCode: flight.Fare.Segments[0][0].Airline.FFAirlineCode,
      // FFNumber: "123",
      GSTCompanyAddress: "",
      GSTCompanyContactNumber: "",
      GSTCompanyName: "",
      GSTNumber: "",
      GSTCompanyEmail: "",
    }));

    let preferenceIndex = 0; // Initialize a counter for the preference index

    const allPassengers = [
      ...adultPassengers.map((passenger) => {
        const mealPreference = passengerMealPreferences[preferenceIndex]
          ? [passengerMealPreferences[preferenceIndex]]
          : null;
        const baggagePreference = passengerBaggagePreferences[preferenceIndex]
          ? [passengerBaggagePreferences[preferenceIndex]]
          : null;
        const seatPreference = passengerSeatPreferences[preferenceIndex]
          ? [passengerSeatPreferences[preferenceIndex]]
          : null;
        preferenceIndex++;
        return {
          ...passenger,
          MealDynamic: mealPreference,
          Baggage: baggagePreference,
          SeatDynamic: seatPreference,
        };
      }),
      ...childPassengers.map((passenger) => {
        const mealPreference = passengerMealPreferences[preferenceIndex]
          ? [passengerMealPreferences[preferenceIndex]]
          : null;
        const baggagePreference = passengerBaggagePreferences[preferenceIndex]
          ? [passengerBaggagePreferences[preferenceIndex]]
          : null;
        const seatPreference = passengerSeatPreferences[preferenceIndex]
          ? [passengerSeatPreferences[preferenceIndex]]
          : null;
        preferenceIndex++;
        return {
          ...passenger,
          MealDynamic: mealPreference,
          Baggage: baggagePreference,
          SeatDynamic: seatPreference,
        };
      }),
      ...infantPassengers.map((passenger) => {
        const mealPreference = passengerMealPreferences[preferenceIndex]
          ? [passengerMealPreferences[preferenceIndex]]
          : null;
        const baggagePreference = passengerBaggagePreferences[preferenceIndex]
          ? [passengerBaggagePreferences[preferenceIndex]]
          : null;
        const seatPreference = passengerSeatPreferences[preferenceIndex]
          ? [passengerSeatPreferences[preferenceIndex]]
          : null;
        preferenceIndex++;
        return {
          ...passenger,
          MealDynamic: mealPreference,
          Baggage: baggagePreference,
          SeatDynamic: seatPreference,
        };
      }),
    ];

    const requestData = {
      PreferredCurrency: null,
      ResultIndex: decodedIndex,
      Passengers: allPassengers,
      EndUserIp: "192.168.11.58",
      TokenId: token,
      TraceId: traceId,
      GSTCompanyAddress:
        "A2, Palam-Dabri Road, Mahavir Enclave, Dwarka, New Delhi, India",
      GSTCompanyContactNumber: "9015858565",
      GSTCompanyName: "eWeblink Technology LLP",
      GSTNumber: "07AAFFE6846H1Z9",
      GSTCompanyEmail: "info@eweblink.net",
    };
    console.log("TBO one way requestData", requestData);

    // Make the API call
    const apiUrl = flight.IsLCC
      ? "https://admin.lelotrip.com/api/flight-ticket"
      : "https://admin.lelotrip.com/api/flight-book";

    // flight &&
    //   flight.IsLCC &&
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          navigate("/404");
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the API response data
        console.log("LCC Flight ticket booked successfully:", data);
        if (!data.success) {
          navigate("/404");
        }
        sessionStorage.setItem(
          "BookingId",
          JSON.stringify(data.data.Response.BookingId)
        );
        sessionStorage.setItem("PNR", JSON.stringify(data.data.Response.PNR));
        sessionStorage.setItem(
          "FirstName",
          data.data.Response.FlightItinerary.Passenger[0].FirstName
        );
        sessionStorage.setItem(
          "LastName",
          data.data.Response.FlightItinerary.Passenger[0].LastName
        );

        sessionStorage.removeItem("BookingId2");
        sessionStorage.removeItem("FirstName2");
        sessionStorage.removeItem("LastName2");
        sessionStorage.removeItem("PNR2");
        // You can perform further actions here based on the API response

        // if (!flight.IsLCC && data.success) {
        //   // Extract PNR and BookingId
        //   const { PNR, BookingId } = data.data.Response;
        //   // Make API call to get flight ticket
        //   fetch("https://admin.lelotrip.com/api/flight-ticket", {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       EndUserIp: "192.168.10.10",
        //       TokenId: token,
        //       TraceId: requestData.TraceId,
        //       BookingId,
        //       PNR,
        //     }),
        //   })
        //     .then((ticketResponse) => ticketResponse.json())
        //     .then((ticketData) => {
        //       // Handle ticket data
        //       console.log("nonLCC Flight ticket data:", ticketData);
        //       if (!ticketData.success) {
        //         navigate("/404");
        //       }
        //     })
        //     .catch((error) => {
        //       // Handle errors
        //       console.error(
        //         "Error while fetching NonLCC flight ticket:",
        //         error
        //       );
        //       navigate("/404");
        //     });
        // }

        !flight2 && navigate(`/flight-ticket/${encodeURIComponent(srdvIdx)}`);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error while booking LCC flight ticket:", error);
        navigate("/404");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTicketBookRound = () => {
    setLoading(true);
    let isFirstAdult = true;

    const adultPassengers = formData.map((data, index) => {
      const isLeadPax = isFirstAdult;
      isFirstAdult = false;
      return {
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 1,
        DateOfBirth: formatDate(data.date),
        Gender: data.title.toLowerCase() === "mr" ? 1 : 2,
        // PassportNo: "KJHHJKHKJH",
        // PassportExpiry: "2025-12-06T00:00:00",
        AddressLine1: "123, Test",
        Fare: {
          Currency: flight2.FareBreakdown[0].Currency,
          BaseFare: flight2.FareBreakdown[0].BaseFare,
          Tax: flight2.FareBreakdown[0].Tax,
          TaxBreakup: flight2.FareBreakdown[0].TaxBreakup,
          YQTax: flight2.FareBreakdown[0].YQTax,
          AdditionalTxnFeeOfrd: flight2.FareBreakdown[0].AdditionalTxnFeeOfrd,
          AdditionalTxnFeePub: flight2.FareBreakdown[0].AdditionalTxnFeePub,
          PGCharge: flight2.FareBreakdown[0].PGCharge,
          OtherCharges: flight2.Fare.OtherCharges,
          ChargeBU: flight2.Fare.ChargeBU,
          Discount: flight2.Fare.Discount,
          PublishedFare: flight2.Fare.PublishedFare,
          CommissionEarned: flight2.Fare.CommissionEarned,
          PLBEarned: flight2.Fare.PLBEarned,
          IncentiveEarned: flight2.Fare.IncentiveEarned,
          OfferedFare: flight2.Fare.OfferedFare,
          TdsOnCommission: flight2.Fare.TdsOnCommission,
          TdsOnPLB: flight2.Fare.TdsOnPLB,
          TdsOnIncentive: flight2.Fare.TdsOnIncentive,
          ServiceFee: flight2.Fare.ServiceFee,
          TotalBaggageCharges: flight2.Fare.TotalBaggageCharges,
          TotalMealCharges: flight2.Fare.TotalMealCharges,
          TotalSeatCharges: flight2.Fare.TotalSeatCharges,
          TotalSpecialServiceCharges: flight2.Fare.TotalSpecialServiceCharges,
        },
        City: "Gurgaon",
        CountryCode: "IN",
        CountryName: "India",
        Nationality: "IN",
        ContactNo: "9879879877",
        Email: "harsh@tbtq.in",
        IsLeadPax: isLeadPax,
        // FFAirlineCode: flight2.Fare.Segments[0][0].Airline.FFAirlineCode,
        // FFNumber: "123",
        GSTCompanyAddress: "",
        GSTCompanyContactNumber: "",
        GSTCompanyName: "",
        GSTNumber: "",
        GSTCompanyEmail: "",
      };
    });

    const childPassengers = childData.map((data) => ({
      Title: data.title,
      FirstName: data.firstName,
      LastName: data.lastName,
      PaxType: 2,
      DateOfBirth: formatDate(data.date),
      Gender: data.title.toLowerCase() === "mr" ? 1 : 2,
      // PassportNo: "KJHHJKHKJH",
      // PassportExpiry: "2025-12-06T00:00:00",
      AddressLine1: "123, Test",
      Fare: {
        Currency: flight2.FareBreakdown[1].Currency,
        BaseFare: flight2.FareBreakdown[1].BaseFare,
        Tax: flight2.FareBreakdown[1].Tax,
        TaxBreakup: flight2.FareBreakdown[1].TaxBreakup,
        YQTax: flight2.FareBreakdown[1].YQTax,
        AdditionalTxnFeeOfrd: flight2.FareBreakdown[1].AdditionalTxnFeeOfrd,
        AdditionalTxnFeePub: flight2.FareBreakdown[1].AdditionalTxnFeePub,
        PGCharge: flight2.FareBreakdown[1].PGCharge,
        OtherCharges: flight2.Fare.OtherCharges,
        ChargeBU: flight2.Fare.ChargeBU,
        Discount: flight2.Fare.Discount,
        PublishedFare: flight2.Fare.PublishedFare,
        CommissionEarned: flight2.Fare.CommissionEarned,
        PLBEarned: flight2.Fare.PLBEarned,
        IncentiveEarned: flight2.Fare.IncentiveEarned,
        OfferedFare: flight2.Fare.OfferedFare,
        TdsOnCommission: flight2.Fare.TdsOnCommission,
        TdsOnPLB: flight2.Fare.TdsOnPLB,
        TdsOnIncentive: flight2.Fare.TdsOnIncentive,
        ServiceFee: flight2.Fare.ServiceFee,
        TotalBaggageCharges: flight2.Fare.TotalBaggageCharges,
        TotalMealCharges: flight2.Fare.TotalMealCharges,
        TotalSeatCharges: flight2.Fare.TotalSeatCharges,
        TotalSpecialServiceCharges: flight2.Fare.TotalSpecialServiceCharges,
      },
      City: "Gurgaon",
      CountryCode: "IN",
      CountryName: "India",
      Nationality: "IN",
      ContactNo: "9879879877",
      Email: "harsh@tbtq.in",
      IsLeadPax: false,
      // FFAirlineCode: flight2.Fare.Segments[0][0].Airline.FFAirlineCode,
      // FFNumber: "123",
      GSTCompanyAddress: "",
      GSTCompanyContactNumber: "",
      GSTCompanyName: "",
      GSTNumber: "",
      GSTCompanyEmail: "",
    }));

    const infantPassengers = infant.map((data) => ({
      Title: data.title,
      FirstName: data.firstName,
      LastName: data.lastName,
      PaxType: 3,
      DateOfBirth: formatDate(data.date),
      Gender: data.title.toLowerCase() === "mr" ? 1 : 2,
      // PassportNo: "KJHHJKHKJH",
      // PassportExpiry: "2025-12-06T00:00:00",
      AddressLine1: "123, Test",
      Fare: {
        Currency: flight2.FareBreakdown[2].Currency,
        BaseFare: flight2.FareBreakdown[2].BaseFare,
        Tax: flight2.FareBreakdown[2].Tax,
        TaxBreakup: flight2.FareBreakdown[2].TaxBreakup,
        YQTax: flight2.FareBreakdown[2].YQTax,
        AdditionalTxnFeeOfrd: flight2.FareBreakdown[2].AdditionalTxnFeeOfrd,
        AdditionalTxnFeePub: flight2.FareBreakdown[2].AdditionalTxnFeePub,
        PGCharge: flight2.FareBreakdown[2].PGCharge,
        OtherCharges: flight2.Fare.OtherCharges,
        ChargeBU: flight2.Fare.ChargeBU,
        Discount: flight2.Fare.Discount,
        PublishedFare: flight2.Fare.PublishedFare,
        CommissionEarned: flight2.Fare.CommissionEarned,
        PLBEarned: flight2.Fare.PLBEarned,
        IncentiveEarned: flight2.Fare.IncentiveEarned,
        OfferedFare: flight2.Fare.OfferedFare,
        TdsOnCommission: flight2.Fare.TdsOnCommission,
        TdsOnPLB: flight2.Fare.TdsOnPLB,
        TdsOnIncentive: flight2.Fare.TdsOnIncentive,
        ServiceFee: flight2.Fare.ServiceFee,
        TotalBaggageCharges: flight2.Fare.TotalBaggageCharges,
        TotalMealCharges: flight2.Fare.TotalMealCharges,
        TotalSeatCharges: flight2.Fare.TotalSeatCharges,
        TotalSpecialServiceCharges: flight2.Fare.TotalSpecialServiceCharges,
      },
      City: "Gurgaon",
      CountryCode: "IN",
      CountryName: "India",
      Nationality: "IN",
      ContactNo: "9879879877",
      Email: "harsh@tbtq.in",
      IsLeadPax: false,
      // FFAirlineCode: flight2.Fare.Segments[0][0].Airline.FFAirlineCode,
      // FFNumber: "123",
      GSTCompanyAddress: "",
      GSTCompanyContactNumber: "",
      GSTCompanyName: "",
      GSTNumber: "",
      GSTCompanyEmail: "",
    }));

    const allPassengers = [
      ...adultPassengers,
      ...childPassengers,
      ...infantPassengers,
    ];

    const requestData = {
      PreferredCurrency: null,
      ResultIndex: decodedIndex2,
      Passengers: allPassengers,
      EndUserIp: "192.168.11.58",
      TokenId: token,
      TraceId: traceId,
      GSTCompanyAddress:
        "A2, Palam-Dabri Road, Mahavir Enclave, Dwarka, New Delhi, India",
      GSTCompanyContactNumber: "9015858565",
      GSTCompanyName: "eWeblink Technology LLP",
      GSTNumber: "07AAFFE6846H1Z9",
      GSTCompanyEmail: "info@eweblink.net",
    };
    console.log("requestData2", requestData);

    // Make the API call
    const apiUrl = flight2.IsLCC
      ? "https://admin.lelotrip.com/api/flight-ticket"
      : "https://admin.lelotrip.com/api/flight-book";

    // flight2 &&
    //   flight2.IsLCC &&
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          navigate("/404");
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data2) => {
        // Handle the API response data
        console.log("LCC flight2 ticket booked successfully:", data2);
        if (!data2.success) {
          navigate("/404");
        }
        sessionStorage.setItem(
          "BookingId2",
          JSON.stringify(data2.data.Response.BookingId)
        );
        sessionStorage.setItem("PNR2", JSON.stringify(data2.data.Response.PNR));
        sessionStorage.setItem(
          "FirstName2",
          data2.data.Response.FlightItinerary.Passenger[0].FirstName
        );
        sessionStorage.setItem(
          "LastName2",
          data2.data.Response.FlightItinerary.Passenger[0].LastName
        );
        // You can perform further actions here based on the API response

        if (!flight2.IsLCC && data2.success) {
          // Extract PNR and BookingId
          const { PNR, BookingId } = data2.data.Response;
          // Make API call to get flight2 ticket
          fetch("https://admin.lelotrip.com/api/flight-ticket", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              EndUserIp: "192.168.10.10",
              TokenId: token,
              TraceId: requestData.TraceId,
              BookingId,
              PNR,
            }),
          })
            .then((ticketResponse) => ticketResponse.json())
            .then((ticketData) => {
              // Handle ticket data
              console.log("LCC flight2 ticket data:", ticketData);
            })
            .catch((error) => {
              // Handle errors
              console.error(
                "Error while fetching NonLCC flight2 ticket:",
                error
              );
              navigate("/404");
            });
        }

        navigate(`/flight-ticket/${encodeURIComponent(srdvIdx)}`);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error while booking LCC flight2 ticket:", error);
        navigate("/404");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTicketBookParto = async () => {
    setLoading(true);
    const adultPassengers = formData.map((data, index) => {
      return {
        DateOfBirth: formatDate(data.date),
        Gender: data.title.toLowerCase() === "mr" ? "Male" : "Female",
        PassengerType: "Adt",
        PassengerName: {
          PassengerFirstName: data.firstName,
          PassengerMiddleName: "",
          PassengerLastName: data.lastName,
          PassengerTitle: data.title,
        },
        Passport: {
          Country: "US",
          ExpiryDate: data.expirydate,
          IssueDate: data.issuedate,
          PassportNumber: data.passport,
        },
        Nationality: "US",
        SeatPreference: "Any",
        MealPreference: "Any",
        Wheelchair: false,
        DestinationAddress: "99A Lane 04",
      };
    });

    const childPassengers = childData.map((data) => ({
      DateOfBirth: formatDate(data.date),
      Gender: data.title.toLowerCase() === "mr" ? "Male" : "Female",
      PassengerType: "Chd",
      PassengerName: {
        PassengerFirstName: data.firstName,
        PassengerMiddleName: "",
        PassengerLastName: data.lastName,
        PassengerTitle:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
      },
      Passport: {
        Country: "US",
        ExpiryDate: data.expirydate,
        IssueDate: data.issuedate,
        PassportNumber: data.passport,
      },
      Nationality: "US",
      SeatPreference: "Any",
      MealPreference: "Any",
      Wheelchair: false,
      DestinationAddress: "99A Lane 04",
    }));

    const infantPassengers = infant.map((data) => ({
      DateOfBirth: formatDate(data.date), // Replace with actual date of birth
      Gender: data.title.toLowerCase() === "mr" ? "Male" : "Female",
      PassengerType: "Inf",
      PassengerName: {
        PassengerFirstName: data.firstName,
        PassengerMiddleName: "",
        PassengerLastName: data.lastName,
        PassengerTitle:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
      },
      Passport: {
        Country: "US",
        ExpiryDate: data.expirydate,
        IssueDate: data.issuedate,
        PassportNumber: data.passport,
      },
      // NationalId: "4900104132", // Replace with actual NationalId
      Nationality: "US",
      SeatPreference: "Any",
      MealPreference: "Any",
      Wheelchair: false,
      DestinationAddress: "99A Lane 04", // Replace with actual address
    }));

    // Combine all passengers
    const allPassengers = [
      ...adultPassengers,
      ...childPassengers,
      ...infantPassengers,
    ];

    const requestData = {
      SessionId: sessionId,
      FareSourceCode: decodedIndex,
      MarkupForAdult: 0,
      MarkupForChild: 0,
      MarkupForInfant: 0,
      CancellationGuaranteeId: null,
      TravelerInfo: {
        PhoneNumber: "123456789",
        Email: "IT@Partocrs.com",
        AirTravelers: allPassengers,
      },
    };

    console.log("parto req data", requestData);

    const apiUrl = "https://admin.lelotrip.com/api/air_book";

    try {
      const response = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      console.log("Flight booked successfully:", data);

      if (!data.success) {
        throw new Error("Booking failed");
      }

      sessionStorage.setItem("UniqueId", JSON.stringify(data.data.UniqueId));

      sessionStorage.removeItem("BookingId2");
      sessionStorage.removeItem("FirstName2");
      sessionStorage.removeItem("LastName2");
      sessionStorage.removeItem("PNR2");

      navigate(`/flight-ticket/${encodeURIComponent(srdvIdx)}`);
    } catch (error) {
      console.error("Error while booking flight ticket:", error);
      // navigate("/404");
    } finally {
      setLoading(false);
    }
  };
  // const handleTicketBookTJ = async () => {
  //   setLoading(true);
  //   // console.log("traveller data", formData);
  //   // console.log("traveller data", childData);
  //   // console.log("traveller data", infant);
  //   const formatPassenger = (data, type, dob) => ({
  //     ti:
  //       type !== "ADULT"
  //         ? data.title === "Mr" || data.title === "Mrs"
  //           ? "Master"
  //           : data.title
  //         : data.title,
  //     fN: data.firstName,
  //     lN: data.lastName,
  //     pt: type,
  //     dob: data.date,
  //     pNat: "IN",
  //     pNum: data.passport,
  //     eD: data.expirydate,
  //     pid: data.issuedate,
  //   });

  //   const adultPassengers = formData.map((data) =>
  //     formatPassenger(data, "ADULT", "1996-08-09")
  //   );
  //   const childPassengers = childData.map((data) =>
  //     formatPassenger(data, "CHILD", "2016-08-09")
  //   );
  //   const infantPassengers = infant.map((data) =>
  //     formatPassenger(data, "INFANT", "2022-08-09")
  //   );

  //   // Combine all passengers
  //   const allPassengers = [
  //     ...adultPassengers,
  //     ...childPassengers,
  //     ...infantPassengers,
  //   ];

  //   const requestData = {
  //     bookingId: sessionStorage.getItem("BookingIdTJ"),
  //     paymentInfos: [
  //       {
  //         amount: flight.totalPriceInfo.totalFareDetail.fC.TF,
  //       },
  //     ],
  //     travellerInfo: allPassengers,
  //     gstInfo: {
  //       gstNumber: "07AAFFE6846H1Z9",
  //       email: "apitest@apitest.com",
  //       registeredName: "XYZ Pvt Ltd",
  //       mobile: "9728408906",
  //       address: "Delhi",
  //     },
  //     deliveryInfo: {
  //       emails: ["xyz@xyz.com"],
  //       contacts: ["9489275524"],
  //     },
  //   };

  //   console.log("TJ book request data", requestData);

  //   const apiUrl = "https://admin.lelotrip.com/api/flight_booking";

  //   try {
  //     const response = await axios.post(apiUrl, requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     const data = response.data;
  //     console.log("TJ Flight booked successfully:", data);

  //     if (!data.success) {
  //       throw new Error("Booking failed");
  //     }

  //     // sessionStorage.setItem("UniqueId", JSON.stringify(data.data.UniqueId));

  //     sessionStorage.removeItem("BookingId2");
  //     sessionStorage.removeItem("FirstName2");
  //     sessionStorage.removeItem("LastName2");
  //     sessionStorage.removeItem("PNR2");

  //     navigate(`/flight-ticket/${encodeURIComponent(srdvIdx)}`);
  //   } catch (error) {
  //     console.error("Error while booking flight ticket:", error);
  //     // navigate("/404");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const [error, setError] = useState(null);
  const handleTicketBookTJ = async () => {
    setLoading(true);
    setError(null);

    const formatPassenger = (data, type) => ({
      ti:
        type !== "ADULT"
          ? data.title === "Mr" || data.title === "Mrs"
            ? "Master"
            : data.title
          : data.title,
      fN: data.firstName,
      lN: data.lastName,
      pt: type,
      dob: data.date,
      pNat: "IN",
      pNum: data.passport,
      eD: data.expirydate,
      pid: data.issuedate,
    });

    const adultPassengers = formData.map((data) =>
      formatPassenger(data, "ADULT")
    );
    const childPassengers = childData.map((data) =>
      formatPassenger(data, "CHILD")
    );
    const infantPassengers = infant.map((data) =>
      formatPassenger(data, "INFANT")
    );

    const allPassengers = [
      ...adultPassengers,
      ...childPassengers,
      ...infantPassengers,
    ];

    // PhonePe Payment Configuration
    const merchantId = "LELOTRIPUAT";
    const saltKey = "982bd35b-ea55-4df2-a5af-3d9489d83f4b";
    const saltIndex = 1;
    const merchantTransactionId =
      "REF_PREFIX" + new Date().toISOString().replace(/[-:.]/g, "");
    const amountInPaise = flight.totalPriceInfo.totalFareDetail.fC.TF * 100;

    const paymentData = {
      merchantId,
      merchantTransactionId,
      merchantUserId: "MUID12875",
      amount: amountInPaise,
      redirectUrl: "http://localhost:3000/flight-ticket/SrdvTJ",
      redirectMode: "REDIRECT",
      callbackUrl: `https://lelotrip.com/phonepe/callback?transactionId=${merchantTransactionId}`,
      callbackMode: "POST",
      mobileNumber: "YOUR_PHONE_NUMBER", // Use the actual phone number
      paymentInstrument: { type: "PAY_PAGE" },
    };

    const encodedData = btoa(JSON.stringify(paymentData));
    const stringToHash = `${encodedData}/pg/v1/pay${saltKey}`;
    const sha256Hash = CryptoJS.SHA256(stringToHash).toString();
    const finalXHeader = `${sha256Hash}###${saltIndex}`;

    try {
      // Step 1: Initiate Payment
      const paymentResponse = await axios.post(
        "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",
        { request: encodedData },
        {
          headers: {
            "Content-Type": "application/json",
            "X-VERIFY": finalXHeader,
            accept: "application/json",
          },
        }
      );

      const redirectUrl =
        paymentResponse.data.data.instrumentResponse.redirectInfo.url;
      window.location.href = redirectUrl; // Redirect to PhonePe for payment

      // Assuming the payment is successful, now proceed to booking
      const bookingRequestData = {
        bookingId: sessionStorage.getItem("BookingIdTJ"),
        paymentInfos: [{ amount: flight.totalPriceInfo.totalFareDetail.fC.TF }],
        travellerInfo: allPassengers,
        gstInfo: {
          gstNumber: "07AAFFE6846H1Z9",
          email: "apitest@apitest.com",
          registeredName: "XYZ Pvt Ltd",
          mobile: "9728408906",
          address: "Delhi",
        },
        deliveryInfo: {
          emails: ["xyz@xyz.com"],
          contacts: ["9489275524"],
        },
      };

      // Step 2: Make Booking
      const bookingResponse = await axios.post(
        "https://admin.lelotrip.com/api/flight_booking",
        bookingRequestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!bookingResponse.data.success) {
        throw new Error("Booking failed: " + bookingResponse.data.message);
      }

      console.log("Flight booked successfully:", bookingResponse.data);
      sessionStorage.removeItem("BookingIdTJ"); // Clear session storage
      // Navigate to the ticket confirmation page
      navigate(
        `/flight-ticket/${encodeURIComponent(
          bookingResponse.data.data.UniqueId
        )}`
      );
    } catch (error) {
      console.error("Error while booking flight ticket:", error);
      setError("An error occurred during the process: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFlight = () => {
    navigate(-1);
  };

  const validatePassengerData = () => {
    // Define the required fields for each passenger type
    const requiredFields = ["title", "firstName", "lastName", "date"];
    const passportFields = ["passport", "issuedate", "expirydate"];

    // Check if passport details are mandatory
    const isPassportMandatory =
      flight.PricedItinerary.IsPassportIssueDateMandatory ||
      flight.PricedItinerary.IsPassportMandatory ||
      flight.PricedItinerary.IsPassportNameWithSpace;

    // Helper function to check if an object has all required fields filled
    const isValid = (data, index, type) => {
      for (const field of requiredFields) {
        if (!data[field] || data[field].trim() === "") {
          alert(`Please fill out the ${field} for ${type} ${index + 1}`);
          return false;
        }
      }
      // Check for passport fields if they are mandatory
      if (isPassportMandatory) {
        for (const field of passportFields) {
          if (!data[field] || data[field].trim() === "") {
            alert(`Please fill out the ${field} for ${type} ${index + 1}`);
            return false;
          }
        }
      }
      return true;
    };

    // Check if data is present for all passengers
    const adultCount =
      flight.PricedItinerary.AirItineraryPricingInfo.PtcFareBreakdown[0]
        .PassengerTypeQuantity.Quantity;
    const childCount =
      flight.PricedItinerary.AirItineraryPricingInfo.PtcFareBreakdown[1]
        ?.PassengerTypeQuantity.Quantity || 0;
    const infantCount =
      flight.PricedItinerary.AirItineraryPricingInfo.PtcFareBreakdown[2]
        ?.PassengerTypeQuantity.Quantity || 0;

    // Validate adult data
    if (formData.length !== adultCount) {
      alert(`Please fill out details for all ${adultCount} adults`);
      return false;
    }
    for (let i = 0; i < formData.length; i++) {
      if (!isValid(formData[i], i, "adult")) return false;
    }

    // Validate child data
    if (childData.length !== childCount) {
      alert(`Please fill out details for all ${childCount} children`);
      return false;
    }
    for (let i = 0; i < childData.length; i++) {
      if (!isValid(childData[i], i, "child")) return false;
    }

    // Validate infant data
    if (infant.length !== infantCount) {
      alert(`Please fill out details for all ${infantCount} infants`);
      return false;
    }
    for (let i = 0; i < infant.length; i++) {
      if (!isValid(infant[i], i, "infant")) return false;
    }

    return true;
  };

  console.log("formdatacvhgdvu", formData);
  console.log("flight", flight);
  // console.log("passengers", passengers);
  // console.log("flight2", flight2);

  console.log("openpaybtn1", openPayBtn);
  console.log("paymentgateway1", paymentGateway);
  console.log("SSR TJ", ssrResponse);
  return (
    <div className="roundtrippg">
      {srdvIdx === "undefined" &&
        !(
          decodedIndex2 !== "" &&
          decodedIndex2 !== "undefined" &&
          decodedIndex2 !== "null"
        ) && (
          <div style={{ position: "relative" }}>
            <Container className="flightBookingMainMain">
              <Row>
                <Col md={9}>
                  <div className="booking_title">
                    <h3> Review Your Booking</h3>
                    <Link
                      className="change_flight"
                      onClick={() => handleChangeFlight()}
                    >
                      Change Flight
                    </Link>
                  </div>
                  {flight ? (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight}
                      type="Departure"
                    />
                  ) : (
                    <FlightDetailSkeleton />
                  )}
                  {flight2 && (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight2}
                      type="Return"
                    />
                  )}
                  {fareRule ? (
                    <FareRule srdvIdx={srdvIdx} fareRule={fareRule} />
                  ) : (
                    <GoodToKnow />
                  )}

                  <Row>
                    <Col md={12} xs={12}>
                      <div
                        id="TRAVELLER_DETAIL"
                        className="oneCard-element"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="componentContainer ">
                          <div className="paxd">
                            <TravellerInformation
                              srdvIdx={srdvIdx}
                              flight={flight}
                              handleInputChange={handleInputChange}
                              handleInputChange1={handleInputChange1}
                              handleInputChange2={handleInputChange2}
                              handleExpiryDateChange={handleExpiryDateChange}
                              handleExpiryDateChange1={handleExpiryDateChange1}
                              handleExpiryDateChange2={handleExpiryDateChange2}
                              handleDOBChange={handleDOBChange}
                              handleDOBChange1={handleDOBChange1}
                              handleDOBChange2={handleDOBChange2}
                            />
                          </div>
                        </div>

                        <div
                          className="componentContainer "
                          style={{ padding: "10px 10px", marginBottom: "20px" }}
                        >
                          <div className="myseatinfo">
                            <div className="booking_title">
                              <h3>Addon Services</h3>
                            </div>
                            <div
                              className="block-content-2 custom_block_content"
                              style={{ border: "none", boxShadow: "none" }}
                            >
                              {flight && (
                                <div
                                  className="box-result custom_box_result"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    className="ssrtabs ssrlists"
                                    style={{ maxHeight: "400px" }}
                                  >
                                    <p className="fw-bold mb-2">
                                      Select Passenger
                                    </p>
                                    <Nav variant="tabs" role="tablist">
                                      {passengers.map((passenger, index) => (
                                        <Nav.Item key={index}>
                                          <Nav.Link
                                            id={`passenger-tab${index}`}
                                            eventKey={index}
                                            active={index === activeTab}
                                            onClick={() =>
                                              handleTabChange(index)
                                            }
                                          >
                                            <div className="d-flex">
                                              <span className="asradio"></span>{" "}
                                              <label
                                                htmlFor={`rdbSSR_${index}`}
                                              >
                                                {passenger.type} {passenger.id}
                                              </label>
                                              <tt id={`adultName_${index}`}>
                                                {/* {passenger.name} */}
                                              </tt>
                                            </div>
                                            <ul className="ssrtabstabs_itinerary">
                                              <li
                                                style={{ marginRight: "10px" }}
                                              >
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-suitcase"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`bagdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                kg
                                              </li>
                                              <li
                                                style={{ marginRight: "10px" }}
                                              >
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-cutlery"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`mealdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                Platter
                                              </li>
                                              <li
                                                style={{ marginRight: "10px" }}
                                              >
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-chair"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`seatdetail_${index}`}>
                                                  {" "}
                                                  No Seat
                                                </tt>
                                                <tt
                                                  id={`seatdetailinput_${index}`}
                                                ></tt>
                                              </li>
                                            </ul>
                                          </Nav.Link>
                                          {/* </a> */}
                                          {/* </li> */}
                                        </Nav.Item>
                                      ))}
                                      {/* </ul> */}
                                    </Nav>
                                  </div>
                                  {/* {ssrResponse && ( */}
                                  <div className="ssr_content tab-content">
                                    {passengers.map((passenger, index) => (
                                      <SSRContent
                                        srdvIdx={srdvIdx}
                                        key={index}
                                        index={index}
                                        passenger={passenger}
                                        passengers={passengers}
                                        activeTab={activeTab}
                                        ssrResponse={ssrResponse}
                                        ssrResponse2={ssrResponse2}
                                        handleChangeCurrency={
                                          handleChangeCurrency
                                        }
                                        passengerBaggagePreferences={
                                          passengerBaggagePreferences
                                        }
                                        setPassengerBaggagePreferences={
                                          setPassengerBaggagePreferences
                                        }
                                        passengerMealPreferences={
                                          passengerMealPreferences
                                        }
                                        setPassengerMealPreferences={
                                          setPassengerMealPreferences
                                        }
                                        passengerSeatPreferences={
                                          passengerSeatPreferences
                                        }
                                        setPassengerSeatPreferences={
                                          setPassengerSeatPreferences
                                        }
                                      />
                                    ))}
                                  </div>
                                  {/* )} */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <BDSend walletData={walletData} />
                      </div>
                    </Col>
                  </Row>
                </Col>
                {!flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    openPayBtn={openPayBtn}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}

                {flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    flight2={flight2}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}
              </Row>
            </Container>
            {openPayBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <FlightPayModal
                srdvIdx={srdvIdx}
                flight={flight}
                flight2={flight2}
                handleChangeCurrency={handleChangeCurrency}
                openPayBtn={openPayBtn}
                setOpenPayBtn={setOpenPayBtn}
                paymentGateway={paymentGateway}
                setPaymentGateway={setPaymentGateway}
                formData={formData}
                childData={childData}
                infant={infant}
                handleTicketBook={handleTicketBook}
                handleTicketBookRound={handleTicketBookRound}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
                loading={loading}
              />
            ) : (
              // </div>
              ""
            )}
            {emiBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <EmiModal emiBtn={emiBtn} setEmiBtn={setEmiBtn} />
            ) : (
              // </div>
              ""
            )}
          </div>
        )}
      {srdvIdx === "SrdvTJ" &&
        !(
          decodedIndex2 !== "" &&
          decodedIndex2 !== "undefined" &&
          decodedIndex2 !== "null"
        ) && (
          <div style={{ position: "relative" }}>
            <Container className="flightBookingMainMain">
              <Row>
                <Col md={9}>
                  <div className="booking_title">
                    <h3> Review Your Booking</h3>
                    <Link
                      className="change_flight"
                      onClick={() => handleChangeFlight()}
                    >
                      Change Flight
                    </Link>
                  </div>
                  {flight ? (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight.tripInfos[0]}
                      type="Departure"
                      flight2={flight.tripInfos[1]}
                    />
                  ) : (
                    <FlightDetailSkeleton />
                  )}
                  {flight2 && (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight2}
                      type="Return"
                    />
                  )}
                  {fareRule ? (
                    <FareRule srdvIdx={srdvIdx} fareRule={fareRule} />
                  ) : (
                    <GoodToKnow />
                  )}

                  <Row>
                    <Col md={12} xs={12}>
                      <div
                        id="TRAVELLER_DETAIL"
                        className="oneCard-element"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="componentContainer ">
                          <div className="paxd">
                            {flight && (
                              <TravellerInformation
                                srdvIdx={srdvIdx}
                                flight={flight}
                                handleInputChange={handleInputChange}
                                handleInputChange1={handleInputChange1}
                                handleInputChange2={handleInputChange2}
                                handleExpiryDateChange={handleExpiryDateChange}
                                handleExpiryDateChange1={
                                  handleExpiryDateChange1
                                }
                                handleExpiryDateChange2={
                                  handleExpiryDateChange2
                                }
                                handleDOBChange={handleDOBChange}
                                handleDOBChange1={handleDOBChange1}
                                handleDOBChange2={handleDOBChange2}
                              />
                            )}
                          </div>
                        </div>

                        <div
                          className="componentContainer "
                          style={{ padding: "10px 10px", marginBottom: "20px" }}
                        >
                          <div className="myseatinfo">
                            <div className="booking_title">
                              <h3>Addon Services</h3>
                            </div>
                            <div
                              className="block-content-2 custom_block_content"
                              style={{ border: "none", boxShadow: "none" }}
                            >
                              {flight && (
                                <div
                                  className="box-result custom_box_result"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    className="ssrtabs ssrlists"
                                    style={{ maxHeight: "400px" }}
                                  >
                                    <p className="fw-bold mb-2">
                                      Select Passenger
                                    </p>
                                    <Nav variant="tabs" role="tablist">
                                      {passengers.map((passenger, index) => (
                                        <Nav.Item key={index}>
                                          <Nav.Link
                                            id={`passenger-tab${index}`}
                                            eventKey={index}
                                            active={index === activeTab}
                                            onClick={() =>
                                              handleTabChange(index)
                                            }
                                          >
                                            <div className="d-flex">
                                              <span className="asradio"></span>{" "}
                                              <label
                                                htmlFor={`rdbSSR_${index}`}
                                              >
                                                {passenger.type} {passenger.id}
                                              </label>
                                              <tt id={`adultName_${index}`}>
                                                {/* {passenger.name} */}
                                              </tt>
                                            </div>
                                            <ul className="ssrtabstabs_itinerary">
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-suitcase"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`bagdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                kg
                                              </li>
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-cutlery"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`mealdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                Platter
                                              </li>
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-chair"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`seatdetail_${index}`}>
                                                  {" "}
                                                  No Seat
                                                </tt>
                                                <tt
                                                  id={`seatdetailinput_${index}`}
                                                ></tt>
                                              </li>
                                            </ul>
                                          </Nav.Link>
                                          {/* </a> */}
                                          {/* </li> */}
                                        </Nav.Item>
                                      ))}
                                      {/* </ul> */}
                                    </Nav>
                                  </div>
                                  {/* {ssrResponse && ( */}
                                  <div className="ssr_content tab-content">
                                    {passengers.map((passenger, index) => (
                                      <SSRContent
                                        srdvIdx={srdvIdx}
                                        key={index}
                                        index={index}
                                        passenger={passenger}
                                        passengers={passengers}
                                        activeTab={activeTab}
                                        ssrResponse={ssrResponse}
                                        ssrResponse2={ssrResponse2}
                                        handleChangeCurrency={
                                          handleChangeCurrency
                                        }
                                        passengerBaggagePreferences={
                                          passengerBaggagePreferences
                                        }
                                        setPassengerBaggagePreferences={
                                          setPassengerBaggagePreferences
                                        }
                                        passengerMealPreferences={
                                          passengerMealPreferences
                                        }
                                        setPassengerMealPreferences={
                                          setPassengerMealPreferences
                                        }
                                        passengerSeatPreferences={
                                          passengerSeatPreferences
                                        }
                                        setPassengerSeatPreferences={
                                          setPassengerSeatPreferences
                                        }
                                      />
                                    ))}
                                  </div>
                                  {/* )} */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <BDSend walletData={walletData} />
                      </div>
                    </Col>
                  </Row>
                </Col>
                {!flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}

                {flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    flight2={flight2}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}
              </Row>
            </Container>
            {openPayBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <FlightPayModal
                srdvIdx={srdvIdx}
                flight={flight}
                flight2={flight2}
                handleChangeCurrency={handleChangeCurrency}
                openPayBtn={openPayBtn}
                setOpenPayBtn={setOpenPayBtn}
                paymentGateway={paymentGateway}
                setPaymentGateway={setPaymentGateway}
                formData={formData}
                childData={childData}
                infant={infant}
                handleTicketBook={handleTicketBook}
                handleTicketBookTJ={handleTicketBookTJ}
                handleTicketBookRound={handleTicketBookRound}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
                loading={loading}
              />
            ) : (
              // </div>

              ""
            )}
            {emiBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <EmiModal
                srdvIdx={srdvIdx}
                emiBtn={emiBtn}
                setEmiBtn={setEmiBtn}
              />
            ) : (
              // </div>
              ""
            )}
          </div>
        )}{" "}
      {srdvIdx === "SrdvP" &&
        !(
          decodedIndex2 !== "" &&
          decodedIndex2 !== "undefined" &&
          decodedIndex2 !== "null"
        ) && (
          <div style={{ position: "relative" }}>
            <Container className="flightBookingMainMain">
              <Row>
                <Col md={9}>
                  <div className="booking_title">
                    <h3> Review Your Booking</h3>
                    <Link
                      className="change_flight"
                      onClick={() => handleChangeFlight()}
                    >
                      Change Flight
                    </Link>
                  </div>
                  {flight ? (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight.PricedItinerary}
                      type="Departure"
                    />
                  ) : (
                    <FlightDetailSkeleton />
                  )}
                  {/* {flight2 && (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight2}
                      type="Return"
                    />
                  )} */}

                  {flight &&
                    flight.PricedItinerary.OriginDestinationOptions[1] && (
                      <DepatureDetail
                        srdvIdx={srdvIdx}
                        flight={flight.PricedItinerary}
                        type="Return"
                      />
                    )}

                  {/* {fareRule ? (
                    <FareRule srdvIdx={srdvIdx} fareRule={fareRule} />
                  ) : (
                    <GoodToKnow />
                  )} */}

                  <Row>
                    <Col md={12} xs={12}>
                      <div
                        id="TRAVELLER_DETAIL"
                        className="oneCard-element"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="componentContainer ">
                          <div className="paxd">
                            <TravellerInformation
                              srdvIdx={srdvIdx}
                              flight={flight}
                              handleInputChange={handleInputChange}
                              handleInputChange1={handleInputChange1}
                              handleInputChange2={handleInputChange2}
                              handleExpiryDateChange={handleExpiryDateChange}
                              handleExpiryDateChange1={handleExpiryDateChange1}
                              handleExpiryDateChange2={handleExpiryDateChange2}
                              handleDOBChange={handleDOBChange}
                              handleDOBChange1={handleDOBChange1}
                              handleDOBChange2={handleDOBChange2}
                            />
                          </div>
                        </div>

                        <div
                          className="componentContainer "
                          style={{ padding: "10px 10px", marginBottom: "20px" }}
                        >
                          <div className="myseatinfo">
                            <div className="booking_title">
                              <h3>Addon Services</h3>
                            </div>
                            <div
                              className="block-content-2 custom_block_content"
                              style={{ border: "none", boxShadow: "none" }}
                            >
                              {flight && (
                                <div
                                  className="box-result custom_box_result"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    className="ssrtabs ssrlists"
                                    style={{ maxHeight: "400px" }}
                                  >
                                    <p className="fw-bold mb-2">
                                      Select Passenger
                                    </p>
                                    <Nav variant="tabs" role="tablist">
                                      {passengers.map((passenger, index) => (
                                        <Nav.Item key={index}>
                                          <Nav.Link
                                            id={`passenger-tab${index}`}
                                            eventKey={index}
                                            active={index === activeTab}
                                            onClick={() =>
                                              handleTabChange(index)
                                            }
                                          >
                                            <div className="d-flex">
                                              <span className="asradio"></span>{" "}
                                              <label
                                                htmlFor={`rdbSSR_${index}`}
                                              >
                                                {passenger.type} {passenger.id}
                                              </label>
                                              <tt id={`adultName_${index}`}>
                                                {/* {passenger.name} */}
                                              </tt>
                                            </div>
                                            <ul className="ssrtabstabs_itinerary">
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-suitcase"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`bagdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                kg
                                              </li>
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-cutlery"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`mealdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                Platter
                                              </li>
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-chair"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`seatdetail_${index}`}>
                                                  {" "}
                                                  No Seat
                                                </tt>
                                                <tt
                                                  id={`seatdetailinput_${index}`}
                                                ></tt>
                                              </li>
                                            </ul>
                                          </Nav.Link>
                                          {/* </a> */}
                                          {/* </li> */}
                                        </Nav.Item>
                                      ))}
                                      {/* </ul> */}
                                    </Nav>
                                  </div>
                                  {/* {ssrResponse && ( */}
                                  <div className="ssr_content tab-content">
                                    {passengers.map((passenger, index) => (
                                      <SSRContent
                                        srdvIdx={srdvIdx}
                                        key={index}
                                        index={index}
                                        passenger={passenger}
                                        passengers={passengers}
                                        activeTab={activeTab}
                                        ssrResponse={ssrResponse}
                                        ssrResponse2={ssrResponse2}
                                        handleChangeCurrency={
                                          handleChangeCurrency
                                        }
                                        handleChangeCurrency2={
                                          handleChangeCurrency2
                                        }
                                        passengerBaggagePreferences={
                                          passengerBaggagePreferences
                                        }
                                        setPassengerBaggagePreferences={
                                          setPassengerBaggagePreferences
                                        }
                                        passengerMealPreferences={
                                          passengerMealPreferences
                                        }
                                        setPassengerMealPreferences={
                                          setPassengerMealPreferences
                                        }
                                        passengerSeatPreferences={
                                          passengerSeatPreferences
                                        }
                                        setPassengerSeatPreferences={
                                          setPassengerSeatPreferences
                                        }
                                      />
                                    ))}
                                  </div>
                                  {/* )} */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <BDSend walletData={walletData} />
                      </div>
                    </Col>
                  </Row>
                </Col>
                {!flight2 && flight && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight.PricedItinerary}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    handleChangeCurrency2={handleChangeCurrency2}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}

                {flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    flight2={flight2}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}
              </Row>
            </Container>
            {openPayBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <FlightPayModal
                srdvIdx={srdvIdx}
                flight={flight.PricedItinerary}
                flight2={flight2}
                handleChangeCurrency={handleChangeCurrency}
                handleChangeCurrency2={handleChangeCurrency2}
                openPayBtn={openPayBtn}
                setOpenPayBtn={setOpenPayBtn}
                paymentGateway={paymentGateway}
                setPaymentGateway={setPaymentGateway}
                formData={formData}
                childData={childData}
                infant={infant}
                handleTicketBook={handleTicketBook}
                handleTicketBookRound={handleTicketBookRound}
                handleTicketBookParto={handleTicketBookParto}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
                loading={loading}
              />
            ) : (
              // </div>
              ""
            )}
            {emiBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <EmiModal
                srdvIdx={srdvIdx}
                emiBtn={emiBtn}
                setEmiBtn={setEmiBtn}
              />
            ) : (
              // </div>
              ""
            )}
          </div>
        )}
      {srdvIdx === "SrdvTJ" &&
        decodedIndex2 !== "" &&
        decodedIndex2 !== "undefined" &&
        decodedIndex2 !== "null" && (
          <div style={{ position: "relative" }}>
            <Container className="flightBookingMainMain">
              <Row>
                <Col md={9}>
                  <div className="booking_title">
                    <h3> Review Your Booking</h3>
                    <Link
                      className="change_flight"
                      onClick={() => handleChangeFlight()}
                    >
                      Change Flight
                    </Link>
                  </div>
                  {flight ? (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight.tripInfos[0]}
                      type="Departure"
                    />
                  ) : (
                    <FlightDetailSkeleton />
                  )}
                  {flight && (
                    <DepatureDetail
                      srdvIdx={srdvIdx}
                      flight={flight.tripInfos[1]}
                      type="Return"
                    />
                  )}
                  {/* {fareRule2 ? (
                    <FareRule srdvIdx={srdvIdx} fareRule={fareRule2} />
                  ) : (
                    <GoodToKnow />
                  )} */}

                  <Row>
                    <Col md={12} xs={12}>
                      <div
                        id="TRAVELLER_DETAIL"
                        className="oneCard-element"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="componentContainer ">
                          <div className="paxd">
                            {flight && (
                              <TravellerInformation
                                srdvIdx={srdvIdx}
                                flight={flight}
                                handleInputChange={handleInputChange}
                                handleInputChange1={handleInputChange1}
                                handleInputChange2={handleInputChange2}
                                handleExpiryDateChange={handleExpiryDateChange}
                                handleExpiryDateChange1={
                                  handleExpiryDateChange1
                                }
                                handleExpiryDateChange2={
                                  handleExpiryDateChange2
                                }
                                handleDOBChange={handleDOBChange}
                                handleDOBChange1={handleDOBChange1}
                                handleDOBChange2={handleDOBChange2}
                              />
                            )}
                          </div>
                        </div>

                        <div
                          className="componentContainer "
                          style={{ padding: "10px 10px", marginBottom: "20px" }}
                        >
                          <div className="myseatinfo">
                            <div className="booking_title">
                              <h3>Addon Services</h3>
                            </div>
                            <div
                              className="block-content-2 custom_block_content"
                              style={{ border: "none", boxShadow: "none" }}
                            >
                              {flight && (
                                <div
                                  className="box-result custom_box_result"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    className="ssrtabs ssrlists"
                                    style={{ maxHeight: "400px" }}
                                  >
                                    <p className="fw-bold mb-2">
                                      Select Passenger
                                    </p>
                                    <Nav variant="tabs" role="tablist">
                                      {passengers.map((passenger, index) => (
                                        <Nav.Item key={index}>
                                          <Nav.Link
                                            id={`passenger-tab${index}`}
                                            eventKey={index}
                                            active={index === activeTab}
                                            onClick={() =>
                                              handleTabChange(index)
                                            }
                                          >
                                            <div className="d-flex">
                                              <span className="asradio"></span>{" "}
                                              <label
                                                htmlFor={`rdbSSR_${index}`}
                                              >
                                                {passenger.type} {passenger.id}
                                              </label>
                                              <tt id={`adultName_${index}`}>
                                                {/* {passenger.name} */}
                                              </tt>
                                            </div>
                                            <ul className="ssrtabstabs_itinerary">
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-suitcase"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`bagdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                kg
                                              </li>
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-cutlery"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`mealdetail_${index}`}>
                                                  0
                                                </tt>{" "}
                                                Platter
                                              </li>
                                              <li style={{ marginRight: 5 }}>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-chair"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <tt id={`seatdetail_${index}`}>
                                                  {" "}
                                                  No Seat
                                                </tt>
                                                <tt
                                                  id={`seatdetailinput_${index}`}
                                                ></tt>
                                              </li>
                                            </ul>
                                          </Nav.Link>
                                          {/* </a> */}
                                          {/* </li> */}
                                        </Nav.Item>
                                      ))}
                                      {/* </ul> */}
                                    </Nav>
                                  </div>
                                  {ssrResponse && (
                                    <div className="ssr_content tab-content">
                                      {passengers.map((passenger, index) => (
                                        <SSRContent
                                          srdvIdx={srdvIdx}
                                          key={index}
                                          index={index}
                                          passenger={passenger}
                                          passengers={passengers}
                                          activeTab={activeTab}
                                          ssrResponse={ssrResponse}
                                          ssrResponse2={ssrResponse2}
                                          handleChangeCurrency={
                                            handleChangeCurrency
                                          }
                                          passengerBaggagePreferences={
                                            passengerBaggagePreferences
                                          }
                                          setPassengerBaggagePreferences={
                                            setPassengerBaggagePreferences
                                          }
                                          passengerMealPreferences={
                                            passengerMealPreferences
                                          }
                                          setPassengerMealPreferences={
                                            setPassengerMealPreferences
                                          }
                                          passengerSeatPreferences={
                                            passengerSeatPreferences
                                          }
                                          setPassengerSeatPreferences={
                                            setPassengerSeatPreferences
                                          }
                                        />
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <BDSend walletData={walletData} />
                      </div>
                    </Col>
                  </Row>
                </Col>
                {!flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}

                {flight2 && (
                  <ChargesOneWay
                    srdvIdx={srdvIdx}
                    flight={flight}
                    flight2={flight2}
                    setShowdetail={setShowdetail}
                    showdetail={showdetail}
                    handleChangeCurrency={handleChangeCurrency}
                    walletData={walletData}
                    setOpenPayBtn={setOpenPayBtn}
                    openPayBtn={openPayBtn}
                    paymentGateway={paymentGateway}
                    setPaymentGateway={setPaymentGateway}
                    emiBtn={emiBtn}
                    setEmiBtn={setEmiBtn}
                    passengerSeatPreferences={passengerSeatPreferences}
                    passengerMealPreferences={passengerMealPreferences}
                    passengerBaggagePreferences={passengerBaggagePreferences}
                    validatePassengerData={validatePassengerData}
                  />
                )}
              </Row>
            </Container>
            {openPayBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <FlightPayModal
                srdvIdx={srdvIdx}
                flight={flight}
                flight2={flight2}
                handleChangeCurrency={handleChangeCurrency}
                openPayBtn={openPayBtn}
                setOpenPayBtn={setOpenPayBtn}
                paymentGateway={paymentGateway}
                setPaymentGateway={setPaymentGateway}
                formData={formData}
                childData={childData}
                infant={infant}
                handleTicketBook={handleTicketBook}
                handleTicketBookTJ={handleTicketBookTJ}
                handleTicketBookRound={handleTicketBookRound}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
                loading={loading}
              />
            ) : (
              // </div>
              ""
            )}
            {emiBtn ? (
              // <div
              //   style={{
              //     position: "absolute",
              //     width: "100%",
              //     top: "0px",
              //     height: "100%",
              //     zIndex: "10",
              //   }}
              // >
              <EmiModal
                srdvIdx={srdvIdx}
                emiBtn={emiBtn}
                setEmiBtn={setEmiBtn}
              />
            ) : (
              // </div>
              ""
            )}
          </div>
        )}{" "}
    </div>
  );
};

export default FlightDetail;
