import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Travel.css";
import { CiSearch } from "react-icons/ci";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TravelSection = () => {
  var settings = {
    dots: false,
    autoplay: true,
    speed: 2500,
    // autoplaySpeed: 1500,
    infinite: true,
    speed: 100,
    // nextArrow: <NextBtn />,
    // prevArrow: <PreviousBtn />,
    slidesToShow: 1,
    slidesToScroll: 1,
    // responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 3,
    //             infinite: true,
    //             dots: true
    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 2,
    //             initialSlide: 2
    //         }
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1
    //         }
    //     }
    // ]
  };
  return (
    <>
     <div style={{position:"relative"}}>
     <Slider {...settings}>
        <div
          className=" item tourBannerMainPage"
          style={{ background: "url(/Images/banner1.jpg)" }}
        >
          <div className="image-layer-overlay"></div>
          </div>
          <div
          className="item tourBannerMainPage2"
          style={{ background: "url(/Images/banner1.jpg)" }}
        >
          <div className="image-layer-overlay"></div>
          </div>
          <div
          className="item tourBannerMainPage3"
          style={{ background: "url(/Images/banner1.jpg)" }}
        >
          <div className="image-layer-overlay"></div>
          </div>
          </Slider>
          <div className="search-area " style={{bottom:"15%"}}>
            <Container>
              <div className="trip-selection-area text-center">
                <div className="explore-tour-package-banner d-md-block">
                  <h5>
                    <span className="bold-font">Explore Our </span>Tour Package
                  </h5>
                  {/* <div className="d-flex justify-content-center pt-5">
                   
                  </div> */}
                </div>
                {/* <p className="text-white">
                  Group Tour is a pre planned itinerary with a fixed departure
                  date and set number of participants. The itinerary is planned
                  in advance including transportation, accommodation, meals and
                  activities.
                </p> */}
              </div>
              <div className="ui-widget">
                <input
                  type="text"
                  className="form-control ui-autocomplete-input"
                  id="tags"
                  placeholder="Search Destinations"
                  autoComplete="off"
                />
                <Button type="submit" className="search_btn">
                  <CiSearch color="#000" size={20} />
                </Button>
              </div>
              <div className="feature-icon-area">
                <Row>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <div className="d-flex flex-row">
                      <div className="map">
                      
                        <img
                          src="https://www.alhindholidays.com/assets/img/map.png"
                          width={40}
                          alt="Map"
                        />
                      </div>
                      <div>
                        <p className="text-white no-margin">
                          5,000+
                          <br />
                          TRAVELERS
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <div className="d-flex flex-row">
                      <div className="map">
                        <img
                          src="https://www.alhindholidays.com/assets/img/globe.png"
                          width={40}
                          alt="Globe"
                        />
                      </div>
                      <div>
                        <p className="text-white no-margin">
                          200+
                          <br />
                          TOURS COMPLETED
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <div className="d-flex flex-row">
                      <div className="map">
                        <img
                          src="https://www.alhindholidays.com/assets/img/gurantee.png"
                          width={40}
                          alt="Guarantee"
                        />
                      </div>
                      <div>
                        <p className="text-white no-margin">
                          24X7
                          <br />
                          CONCIERGE &amp; ASSISTANCE
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={6} xs={6}>
                    <div className="d-flex flex-row">
                      <div className="map">
                        <img
                          src="https://www.alhindholidays.com/assets/img/users.png"
                          width={40}
                          alt="Users"
                        />
                      </div>
                      <div>
                        <p className="text-white no-margin">
                          50+
                          <br />
                          DESTINATIONS
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div id="home-nav1" />
            </Container>
          </div>
        </div>
       
     
     {/* </div> */}
    </>
  );
};

export default TravelSection;
