import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./FlightInternational.css";
import { FlightListInfo } from "../FlightList/FlightListInfo";
import { FlightListInfoInternational } from "./FlightListInfoInternational";

const InternationalFlightListCard = ({
  e,
  handleMoreFare,
  handleClick,
  activeId,
  showModal,
  setShowModal,
  formatTime,
  handlebookmodal,
  handleChnageCurrency,
  handleChangeCurrency2,
  fareRules,
  // returnFlight,
  // departureFlight,
}) => {
  const [departureFlight, setDepartureFlight] = useState("");
  const [returnFlight, setReturnFlight] = useState("");

  useEffect(() => {
    if (e && e.sI && e.sI.length > 0) {
      // Filter flights for departure (isRs = false)
      const outboundFlights = e.sI.filter((flight) => !flight.isRs);

      // Filter flights for return (isRs = true)
      const inboundFlights = e.sI.filter((flight) => flight.isRs);

      // Update state with all departure flights
      if (outboundFlights.length > 0) {
        setDepartureFlight(outboundFlights); // Store all outbound flights in an array
      }

      // Update state with all return flights
      if (inboundFlights.length > 0) {
        setReturnFlight(inboundFlights); // Store all return flights in an array
      }
    }
  }, [e]);

  return (
    <div className="internationtrip_search">
      {e && departureFlight && returnFlight && (
        <Container className="refendable11 refendable11onword">
          <Row className="price1">
            <Col
              className="price111 price111onword"
              style={{ paddingInline: "5px" }}
            >
              <Row className="flight11" flight="UL">
                <Col className="flight112" flightcraft="">
                  <div className="stopscount" stop="1">
                    <div
                      id="divUL196UL225UL226UL195"
                      className="Price33827 allshow block-content-2 custom_block_content flight-list-v2 ULSri Lankan Airlines 0Stops bingo_button_4"
                    >
                      <div className="box-result custom_box_result">
                        <div className="inter_trip_left">
                          <h4>Depart</h4>

                          <ul
                            className="list-search-result result_list"
                            style={{ display: "flex" }}
                          >
                            <li>
                              <Image
                                src={`/Images/AirlineLogo/${departureFlight[0].fD.aI.code}.gif`}
                                alt=""
                              />
                              <div className="flight_name obflight_name">
                                {departureFlight[0].fD.aI.name}{" "}
                                <span className="flight_no">
                                  {departureFlight &&
                                    departureFlight[0].fD.aI.code}
                                  -{departureFlight && departureFlight[0].fD.fN}
                                </span>
                              </div>
                            </li>
                            <li className="depart_time cus_dep_arr_time">
                              <span className="date departdate">
                                {departureFlight[0] &&
                                  departureFlight[0].da.cityCode}{" "}
                                {new Date(
                                  departureFlight[0] && departureFlight[0].dt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {departureFlight[0] && departureFlight[0].da.city}
                              <div className="date_time">
                                {formatTime(
                                  departureFlight[0] && departureFlight[0].dt
                                )}
                              </div>
                            </li>
                            <li className="flight_time_between">
                              <span className="duration departdur">
                                {/* {departureFlight &&
                                  departureFlight[departureFlight.length - 1]
                                    .duration}
                                m | */}
                                <div className="cus_tooltip">
                                  {departureFlight.length - 1 == 0
                                    ? "Non-Stop"
                                    : `${
                                        departureFlight.length - 1
                                      } Stops`}{" "}
                                  <span className="tooltiptext">
                                    {/* {e.OriginDestinationOptions[0].FlightSegments.map(
                                      (segment, index) =>
                                        segment.DepartureAirportLocationCode
                                    ).join("→")}
                                    →
                                    {
                                      e.OriginDestinationOptions[0]
                                        .FlightSegments[
                                        e.OriginDestinationOptions[0]
                                          .FlightSegments.length - 1
                                      ].ArrivalAirportLocationCode
                                    } */}
                                  </span>
                                </div>
                              </span>
                              <div className="time_separete"></div>
                              <div className="flight_rel">
                                {/* {" "}
                                {e.OriginDestinationOptions[0].FlightSegments.map(
                                  (segment, index) =>
                                    segment.DepartureAirportLocationCode
                                ).join("→")}
                                →
                                {
                                  e.OriginDestinationOptions[0].FlightSegments[
                                    e.OriginDestinationOptions[0].FlightSegments
                                      .length - 1
                                  ].ArrivalAirportLocationCode
                                } */}
                              </div>
                            </li>
                            <li className="arrive_time cus_dep_arr_time">
                              <span className="date arivedate">
                                {departureFlight &&
                                  departureFlight[departureFlight.length - 1].aa
                                    .cityCode}{" "}
                                &nbsp;
                                {new Date(
                                  departureFlight &&
                                    departureFlight[departureFlight.length - 1]
                                      .at
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {departureFlight &&
                                departureFlight[departureFlight.length - 1].aa
                                  .city}
                              <div className="date_time">
                                {formatTime(
                                  departureFlight &&
                                    departureFlight[departureFlight.length - 1]
                                      .at
                                )}
                              </div>
                            </li>
                          </ul>
                          {/* ))} */}
                          <div className="clearfix"></div>
                          <div className="hr_seperator"></div>
                          <h4>Return</h4>
                          {/* {e.Segments[1].map((returnflight, index2) => ( */}
                          <ul
                            className="list-search-result result_list"
                            style={{ display: "flex" }}
                          >
                            <li>
                              <Image
                                src={`/Images/AirlineLogo/${returnFlight[0].fD.aI.code}.gif`}
                                alt=""
                              />
                              <div className="flight_name">
                                {returnFlight[0].fD.aI.name}{" "}
                                <span className="flight_no">
                                  {returnFlight && returnFlight[0].fD.aI.code}-
                                  {returnFlight && departureFlight[0].fD.fN}
                                </span>
                              </div>
                            </li>
                            <li className="depart_time cus_dep_arr_time">
                              <span className="date">
                                {" "}
                                {returnFlight &&
                                  returnFlight[0].da.cityCode}{" "}
                                {new Date(
                                  returnFlight && returnFlight[0].dt
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {returnFlight && returnFlight[0].da.city}
                              <div className="date_time cus_dep_arr_time">
                                {formatTime(returnFlight && returnFlight[0].dt)}
                              </div>
                            </li>
                            <li className="flight_time_between">
                              <span className="duration">
                                {/* {
                                  e.OriginDestinationOptions[1]
                                    .FlightSegments[0].JourneyDuration
                                }{" "}
                                | */}
                                <div className="cus_tooltip">
                                  {returnFlight.length - 1 == 0
                                    ? "Non-Stop"
                                    : `${returnFlight.length - 1} Stops`}{" "}
                                  <span className="tooltiptext">
                                    {/* {e.OriginDestinationOptions[1].FlightSegments.map(
                                      (segment, index) =>
                                        segment.DepartureAirportLocationCode
                                    ).join("→")}
                                    →
                                    {
                                      e.OriginDestinationOptions[1]
                                        .FlightSegments[
                                        e.OriginDestinationOptions[1]
                                          .FlightSegments.length - 1
                                      ].ArrivalAirportLocationCode
                                    } */}
                                  </span>
                                </div>
                              </span>
                              <div className="time_separete"></div>
                              <div className="flight_rel">
                                {" "}
                                {/* {e.OriginDestinationOptions[1].FlightSegments.map(
                                  (segment, index) =>
                                    segment.DepartureAirportLocationCode
                                ).join("→")}
                                →
                                {
                                  e.OriginDestinationOptions[1].FlightSegments[
                                    e.OriginDestinationOptions[1].FlightSegments
                                      .length - 1
                                  ].ArrivalAirportLocationCode
                                } */}
                              </div>
                            </li>
                            <li className="arrive_time cus_dep_arr_time">
                              <span className="date">
                                {returnFlight &&
                                  returnFlight[returnFlight.length - 1].aa
                                    .cityCode}{" "}
                                &nbsp;
                                {new Date(
                                  returnFlight &&
                                    returnFlight[returnFlight.length - 1].at
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                              </span>
                              {returnFlight &&
                                returnFlight[returnFlight.length - 1].aa.city}
                              <div className="date_time">
                                {formatTime(
                                  returnFlight &&
                                    returnFlight[returnFlight.length - 1].at
                                )}
                              </div>
                            </li>
                          </ul>
                          {/* ))} */}
                        </div>
                        <div className="book_flight">
                          <div className="refundable clr_green">
                            {/* <span>
                              {e.IsRefundable ? "Refundable" : "Non-Refundable"}
                            </span> */}
                          </div>
                          <span className="fli_price airlineprice">
                            <span className="mainprice">
                              ₹{e.totalPriceList[0].fd.ADULT.fC.TF}
                            </span>
                          </span>
                          <div className="bookbtn_morefare">
                            <div className="book_btn">
                              <Button
                                className="btn small colorful-transparent hover-colorful btn_green checkout_flight"
                                faretype="offer 1"
                                fareindex="OB2"
                                faretraceid="23c6201f-d7df-45aa-a04e-9d2b533ea956"
                                fareuid="OB2"
                                resultindex="LS1DOFQuIyxYLCYpQSxWLFEsMGBgCmAK"
                                flightindex="NDU0UFEuMzk1MyMoUi01NSwsQyhWNTRQUS4zNGAKYAo="
                                onClick={() =>
                                  handlebookmodal(e.totalPriceList[0].id)
                                }
                              >
                                Book Now
                              </Button>
                            </div>
                            <div className="more_farebtn"></div>
                          </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="flight_details">
                          <div className="main_flight_btn">
                            <a
                              className="details_btn flightdetailajax"
                              onClick={() => {
                                handleClick(e.totalPriceList[0].id, "SrdvP");
                              }}
                            >
                              Flight Details
                            </a>
                          </div>
                          {/* <div
                          className="flight_details_info"
                          is_show="0"
                          id="show_UL196UL225UL226UL195"
                        >
                          <h4>Please wait loading...</h4>
                        </div> */}
                        </div>
                        {activeId === e.totalPriceList[0].id && (
                          <FlightListInfoInternational
                            idx={e.FareSourceCode}
                            flight={e}
                            departureFlight={departureFlight}
                            returnFlight={returnFlight}
                            handleChnageCurrency={handleChnageCurrency}
                            handleChangeCurrency2={handleChangeCurrency2}
                            isInterNational={true}
                            SrdvIndex="SrdvP"
                            fareRules={fareRules}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default InternationalFlightListCard;
