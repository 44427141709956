import React from "react";

const DepatureDetail = ({ srdvIdx, flight, type, flight2 }) => {
  const formatTime = (arrTime) => {
    const date = new Date(arrTime);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = days[date.getDay()];
    const dateNum = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${dateNum} ${month} ${year}`;
  };

  const formatLayoverTime = (arrivalTime, nextDepartureTime) => {
    const arrival = new Date(arrivalTime);
    const nextDeparture = new Date(nextDepartureTime);
    const layoverMinutes = Math.floor(
      (nextDeparture.getTime() - arrival.getTime()) / (1000 * 60)
    );
    const layoverHours = Math.floor(layoverMinutes / 60);
    const layoverMinutesRemainder = layoverMinutes % 60;
    return `${layoverHours}h : ${layoverMinutesRemainder}m`;
  };

  console.log("flightdepdetail", flight2);

  return (
    <div className="block-content-2 custom_block_content">
      {srdvIdx === "undefined" && (
        <div
          className="box-result custom_box_result"
          style={{ display: "block" }}
        >
          <div className="flight_tags depart_tags">
            <span style={{ zIndex: 2 }}>{type}</span>
          </div>
          {flight.Segments[0].map((segment, index) => (
            <React.Fragment key={index}>
              <ul className="list-search-result booking_list forMinScreenul">
                <li className="flight_name">
                  <img
                    src={`/Images/AirlineLogo/${segment.Airline.AirlineCode}.gif`}
                    alt=""
                    style={{ width: "50px" }}
                  />

                  <div className="name">
                    {" "}
                    {segment.Airline.AirlineName}
                    <span className="flight_no">
                      {segment.Airline.AirlineCode}-
                      {segment.Airline.FlightNumber}
                    </span>
                    <span className="flightBookingHiddenTitle">Economy</span>
                  </div>
                </li>
                <li className="flight_time">
                  <span className="flightBookingHiddenDelhi">
                    {" "}
                    {segment.Origin.Airport.CityName},{" "}
                    {segment.Origin.Airport.CountryCode}
                  </span>
                  <strong>
                    {new Date(segment.Origin.DepTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </strong>
                  <span className="date">
                    {formatTime(segment.Origin.DepTime)}
                  </span>
                  <span className="flightBookingHiddenTitle">
                    {" "}
                    {segment.Origin.Airport.CityName},{" "}
                    {segment.Origin.Airport.CountryCode}
                  </span>
                  <span className="airport">
                    {" "}
                    {segment.Origin.Airport.AirportName} , Terminal{" "}
                    {segment.Origin.Airport.Terminal}
                  </span>
                </li>
                <li className="flight_amenties">
                  <div className="top">
                    <span className="duration">
                      <i
                        className="fa fa-clock"
                        style={{ marginRight: "3px" }}
                      />
                      {segment.Duration} m
                    </span>
                    <span className="flightDetailSpantag"></span>
                    <span className="grey_rtbrder">|</span>{" "}
                    <span className="flightBookingHiddenDelhi">Economy </span>
                  </div>

                  <div className="middle">
                    <span className="txt flighttxt">
                      <i className="fa fa-plane" />
                    </span>
                  </div>

                  <div className="bottom">
                    {segment.Baggage && (
                      <span className="wght">
                        {flight.Segments[0][0].Baggage}
                      </span>
                    )}

                    {segment.Baggage && flight.IsRefundable && (
                      <span className="grey_rtbrder">|</span>
                    )}

                    {flight.IsRefundable && (
                      <>
                        <span className="refundable">Refundable</span>
                      </>
                    )}
                  </div>
                </li>
                <li className="flight_time">
                  <span className="flightBookingHiddenDelhi">
                    {segment.Destination.Airport.CityName},{" "}
                    {segment.Destination.Airport.CountryCode}
                  </span>
                  <strong>
                    {new Date(segment.Destination.ArrTime).toLocaleTimeString(
                      [],
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      }
                    )}
                  </strong>
                  <span className="date">
                    {formatTime(segment.Destination.ArrTime)}
                  </span>
                  <span className="flightBookingHiddenTitle"> Pune, IN</span>
                  <span className="airport">
                    {" "}
                    {segment.Destination.Airport.AirportName} , Terminal{" "}
                    {segment.Destination.Airport.Terminal}{" "}
                  </span>
                </li>
              </ul>
              <div className="clearfix" />
              {index < flight.Segments[0].length - 1 && (
                <div className="layover_time">
                  <div className="layover_txt">
                    Layover:
                    <span>
                      {formatLayoverTime(
                        segment.Destination.ArrTime,
                        flight.Segments[0][index + 1].Origin.DepTime
                      )}
                    </span>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
          {/* <div className="layover_time">
                    <div className="layover_txt">
                      Layover:<span>2h : 10m</span>
                    </div>
                  </div>
                  <ul className="list-search-result booking_list forMinScreenul">
                    <li className="flight_name">
                      <img
                        src="https://images-platform.99static.com//ee5DpJb0wvpRSgjbg0FEFl-RZsc=/943x0:1534x591/fit-in/590x590/99designs-contests-attachments/60/60257/attachment_60257611"
                        alt=""
                        style={{ width: "50px" }}
                      />
                      <div className="name">
                        {" "}
                        AirAsia
                        <span className="flight_no">I5-721</span>
                        <span className="flightBookingHiddenTitle">
                          Economy
                        </span>
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {" "}
                        Delhi, IN
                      </span>
                      <strong>21:25</strong>
                      <span className="date">Mon, 11 Mar 2024</span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        Delhi, IN
                      </span>
                      <span className="airport">
                        {" "}
                        Indira Gandhi Airport , Terminal 3
                      </span>
                    </li>
                    <li className="flight_amenties">
                      <div className="top">
                        <span className="duration">
                          <i className="fa fa-clock" />
                          02h 10m
                        </span>
                        <span className="flightDetailSpantag"></span>
                        <span className="grey_rtbrder">|</span>{" "}
                        
                        <span className="flightBookingHiddenDelhi">
                          Economy{" "}
                        </span>
                      </div>

                      <div className="middle">
                        <span className="txt">
                          <i className="fa fa-plane" /> Flight
                        </span>
                      </div>

                      <div className="bottom">
                        <span className="wght">15KG</span>
                        
                        <span className="grey_rtbrder">|</span>
                        <span className="refundable">Refundable</span>
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">Pune, IN</span>
                      <strong>23:35</strong>
                      <span className="date">Mon, 11 Mar 2024</span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        Pune, IN
                      </span>
                      <span className="airport"> Lohegaon, Terminal </span>
                    </li>
                  </ul>
                  <div className="clearfix" /> */}
        </div>
      )}
      {srdvIdx === "SrdvTJ" && (
        <>
          <div
            className="box-result custom_box_result"
            style={{ display: "block" }}
          >
            <div className="flight_tags depart_tags">
              <span style={{ zIndex: 2 }}>{type}</span>
            </div>
            {flight &&
              flight.sI.map((segment, index) => (
                <React.Fragment key={index}>
                  <ul className="list-search-result booking_list forMinScreenul">
                    <li className="flight_name">
                      <img
                        src={`/Images/AirlineLogo/${segment.fD.aI.code}.gif`}
                        alt=""
                        style={{ width: "50px" }}
                      />

                      <div className="name">
                        {" "}
                        {segment.da.city}
                        <span className="flight_no">
                          {segment.fD.aI.code}-{segment.fD.fN}
                        </span>
                        <span className="flightBookingHiddenTitle">
                          {flight.totalPriceList[0].fd.ADULT.cc}
                        </span>
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {" "}
                        {segment.da.city}, {segment.da.countryCode}
                      </span>
                      <strong>
                        {new Date(segment.dt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </strong>
                      <span className="date">{formatTime(segment.dt)}</span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        {segment.da.city}, {segment.da.countryCode}
                      </span>
                      <span className="airport">
                        {" "}
                        {segment.da.name}, Terminal {segment.da.terminal}
                      </span>
                    </li>
                    <li className="flight_amenties">
                      <div className="top">
                        <span className="duration">
                          <i
                            className="fa fa-clock"
                            style={{ marginRight: "3px" }}
                          />
                          {segment.duration} m
                        </span>
                        <span className="flightDetailSpantag"></span>
                        <span className="grey_rtbrder">|</span>{" "}
                        <span className="flightBookingHiddenDelhi">
                          {flight.totalPriceList[0].fd.ADULT.cc}{" "}
                        </span>
                      </div>

                      <div className="middle">
                        <span className="txt flighttxt">
                          <i className="fa fa-plane" />
                        </span>
                      </div>

                      <div className="bottom">
                        {
                          // segment.Baggage &&
                          <span className="wght">
                            {flight.totalPriceList[0].fd.ADULT.bI.cB}
                          </span>
                        }

                        {flight.totalPriceList[0].fd.ADULT.bI.cB &&
                          flight.totalPriceList[0].fd.ADULT.rT === 1 && (
                            <span className="grey_rtbrder">|</span>
                          )}

                        {flight.totalPriceList[0].fd.ADULT.rT === 1 && (
                          <>
                            <span className="refundable">Refundable</span>
                          </>
                        )}
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {segment.aa.city}, {segment.aa.countryCode}
                      </span>
                      <strong>
                        {new Date(segment.at).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </strong>
                      <span className="date">{formatTime(segment.at)}</span>
                      {/* <span className="flightBookingHiddenTitle"> Pune, IN</span> */}
                      <span className="airport">
                        {" "}
                        {segment.aa.name}, Terminal {segment.aa.terminal}{" "}
                      </span>
                    </li>
                  </ul>
                  <div className="clearfix" />
                  {index < flight.sI.length - 1 && (
                    <div className="layover_time">
                      <div className="layover_txt">
                        Layover:
                        <span>
                          {formatLayoverTime(
                            segment.at,
                            flight.sI[index + 1].dt
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>

          {flight2 && flight2 !== "undefined" &&
          flight2 !== "null" ? (
            <div
              className="box-result custom_box_result"
              style={{ display: "block" }}
            >
              <div className="flight_tags depart_tags">
                <span style={{ zIndex: 2, top: "50%" }}>Return</span>
              </div>
              {flight2 &&
                flight2.sI.map((segment, index) => (
                  <React.Fragment key={index}>
                    <ul className="list-search-result booking_list forMinScreenul">
                      <li className="flight_name">
                        <img
                          src={`/Images/AirlineLogo/${segment.fD.aI.code}.gif`}
                          alt=""
                          style={{ width: "50px" }}
                        />

                        <div className="name">
                          {" "}
                          {segment.da.city}
                          <span className="flight_no">
                            {segment.fD.aI.code}-{segment.fD.fN}
                          </span>
                          <span className="flightBookingHiddenTitle">
                            {flight2.totalPriceList[0].fd.ADULT.cc}
                          </span>
                        </div>
                      </li>
                      <li className="flight_time">
                        <span className="flightBookingHiddenDelhi">
                          {" "}
                          {segment.da.city}, {segment.da.countryCode}
                        </span>
                        <strong>
                          {new Date(segment.dt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </strong>
                        <span className="date">{formatTime(segment.dt)}</span>
                        <span className="flightBookingHiddenTitle">
                          {" "}
                          {segment.da.city}, {segment.da.countryCode}
                        </span>
                        <span className="airport">
                          {" "}
                          {segment.da.name}, Terminal {segment.da.terminal}
                        </span>
                      </li>
                      <li className="flight_amenties">
                        <div className="top">
                          <span className="duration">
                            <i
                              className="fa fa-clock"
                              style={{ marginRight: "3px" }}
                            />
                            {segment.duration} m
                          </span>
                          <span className="flightDetailSpantag"></span>
                          <span className="grey_rtbrder">|</span>{" "}
                          <span className="flightBookingHiddenDelhi">
                            {flight2.totalPriceList[0].fd.ADULT.cc}{" "}
                          </span>
                        </div>

                        <div className="middle">
                          <span className="txt flighttxt">
                            <i className="fa fa-plane" />
                          </span>
                        </div>

                        <div className="bottom">
                          {
                            // segment.Baggage &&
                            <span className="wght">
                              {flight2.totalPriceList[0].fd.ADULT.bI.cB}
                            </span>
                          }

                          {flight2.totalPriceList[0].fd.ADULT.bI.cB &&
                            flight2.totalPriceList[0].fd.ADULT.rT === 1 && (
                              <span className="grey_rtbrder">|</span>
                            )}

                          {flight2.totalPriceList[0].fd.ADULT.rT === 1 && (
                            <>
                              <span className="refundable">Refundable</span>
                            </>
                          )}
                        </div>
                      </li>
                      <li className="flight_time">
                        <span className="flightBookingHiddenDelhi">
                          {segment.aa.city}, {segment.aa.countryCode}
                        </span>
                        <strong>
                          {new Date(segment.at).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </strong>
                        <span className="date">{formatTime(segment.at)}</span>
                        {/* <span className="flightBookingHiddenTitle"> Pune, IN</span> */}
                        <span className="airport">
                          {" "}
                          {segment.aa.name}, Terminal {segment.aa.terminal}{" "}
                        </span>
                      </li>
                    </ul>
                    <div className="clearfix" />
                    {index < flight2.sI.length - 1 && (
                      <div className="layover_time">
                        <div className="layover_txt">
                          Layover:
                          <span>
                            {formatLayoverTime(
                              segment.at,
                              flight2.sI[index + 1].dt
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          ) : (
            ""
          )}
        </>
      )}
      {srdvIdx === "SrdvP" && (
        <div
          className="box-result custom_box_result"
          style={{ display: "block" }}
        >
          <div className="flight_tags depart_tags">
            <span style={{ zIndex: 2 }}>{type}</span>
          </div>
          {type === "Departure" &&
            flight.OriginDestinationOptions[0].FlightSegments.map(
              (segment, index) => (
                <React.Fragment key={index}>
                  <ul className="list-search-result booking_list forMinScreenul">
                    <li className="flight_name">
                      <img
                        src={`/Images/AirlineLogo/${segment.OperatingAirline.Code}.gif`}
                        alt=""
                        style={{ width: "50px" }}
                      />

                      <div className="name">
                        {" "}
                        {segment.DepartureCity.CITYNAME}
                        <span className="flight_no">
                          {segment.OperatingAirline.Code}-
                          {segment.OperatingAirline.FlightNumber}
                        </span>
                        {/* <span className="flightBookingHiddenTitle">Economy</span> */}
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {" "}
                        {segment.DepartureCity.CITYNAME},{" "}
                        {segment.DepartureCity.COUNTRYCODE}
                      </span>
                      <strong>
                        {new Date(segment.DepartureDateTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          }
                        )}
                      </strong>
                      <span className="date">
                        {formatTime(segment.DepartureDateTime)}
                      </span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        {segment.DepartureCity.CITYNAME},{" "}
                        {segment.DepartureCity.COUNTRYCODE}
                      </span>
                      <span className="airport">
                        {" "}
                        {segment.DepartureCity.AIRPORTNAME}
                        {/* , Terminal{" "}{segment.Origin.Airport.Terminal} */}
                      </span>
                    </li>
                    <li className="flight_amenties">
                      <div className="top">
                        <span className="duration">
                          <i
                            className="fa fa-clock"
                            style={{ marginRight: "3px" }}
                          />
                          {segment.JourneyDuration}
                        </span>
                        <span className="flightDetailSpantag"></span>
                        <span className="grey_rtbrder">|</span>{" "}
                        {/* <span className="flightBookingHiddenDelhi">Economy </span> */}
                      </div>

                      <div className="middle">
                        <span className="txt flighttxt">
                          <i className="fa fa-plane" />
                        </span>
                      </div>

                      <div className="bottom">
                        {segment.Baggage && (
                          <span className="wght">{segment.Baggage}</span>
                        )}

                        {segment.Baggage && flight.IsRefundable && (
                          <span className="grey_rtbrder">|</span>
                        )}

                        {/* {flight.IsRefundable && (
                    <>
                      <span className="refundable">Refundable</span>
                    </>
                  )} */}
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {segment.ArrivalCity.CITYNAME},{" "}
                        {segment.ArrivalCity.COUNTRYCODE}
                      </span>
                      <strong>
                        {new Date(segment.ArrivalDateTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          }
                        )}
                      </strong>
                      <span className="date">
                        {formatTime(segment.ArrivalDateTime)}
                      </span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        Pune, IN
                      </span>
                      <span className="airport">
                        {" "}
                        {segment.ArrivalCity.AIRPORTNAME}
                        {/* , Terminal{" "}{segment.Destination.Airport.Terminal}{" "} */}
                      </span>
                    </li>
                  </ul>
                  <div className="clearfix" />
                  {index <
                    flight.OriginDestinationOptions[0].FlightSegments.length -
                      1 && (
                    <div className="layover_time">
                      <div className="layover_txt">
                        Layover:
                        <span>
                          {formatLayoverTime(
                            segment.ArrivalDateTime,
                            flight.OriginDestinationOptions[0].FlightSegments[
                              index + 1
                            ].DepartureDateTime
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )
            )}

          {type === "Return" &&
            flight.OriginDestinationOptions[1].FlightSegments.map(
              (segment, index) => (
                <React.Fragment key={index}>
                  <ul className="list-search-result booking_list forMinScreenul">
                    <li className="flight_name">
                      <img
                        src={`/Images/AirlineLogo/${segment.OperatingAirline.Code}.gif`}
                        alt=""
                        style={{ width: "50px" }}
                      />

                      <div className="name">
                        {" "}
                        {segment.DepartureCity.CITYNAME}
                        <span className="flight_no">
                          {segment.OperatingAirline.Code}-
                          {segment.OperatingAirline.FlightNumber}
                        </span>
                        {/* <span className="flightBookingHiddenTitle">Economy</span> */}
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {" "}
                        {segment.DepartureCity.CITYNAME},{" "}
                        {segment.DepartureCity.COUNTRYCODE}
                      </span>
                      <strong>
                        {new Date(segment.DepartureDateTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          }
                        )}
                      </strong>
                      <span className="date">
                        {formatTime(segment.DepartureDateTime)}
                      </span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        {segment.DepartureCity.CITYNAME},{" "}
                        {segment.DepartureCity.COUNTRYCODE}
                      </span>
                      <span className="airport">
                        {" "}
                        {segment.DepartureCity.AIRPORTNAME}
                        {/* , Terminal{" "}{segment.Origin.Airport.Terminal} */}
                      </span>
                    </li>
                    <li className="flight_amenties">
                      <div className="top">
                        <span className="duration">
                          <i
                            className="fa fa-clock"
                            style={{ marginRight: "3px" }}
                          />
                          {segment.JourneyDuration}
                        </span>
                        <span className="flightDetailSpantag"></span>
                        <span className="grey_rtbrder">|</span>{" "}
                        {/* <span className="flightBookingHiddenDelhi">Economy </span> */}
                      </div>

                      <div className="middle">
                        <span className="txt flighttxt">
                          <i className="fa fa-plane" />
                        </span>
                      </div>

                      <div className="bottom">
                        {segment.Baggage && (
                          <span className="wght">{segment.Baggage}</span>
                        )}

                        {segment.Baggage && flight.IsRefundable && (
                          <span className="grey_rtbrder">|</span>
                        )}

                        {/* {flight.IsRefundable && (
                    <>
                      <span className="refundable">Refundable</span>
                    </>
                  )} */}
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {segment.ArrivalCity.CITYNAME},{" "}
                        {segment.ArrivalCity.COUNTRYCODE}
                      </span>
                      <strong>
                        {new Date(segment.ArrivalDateTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          }
                        )}
                      </strong>
                      <span className="date">
                        {formatTime(segment.ArrivalDateTime)}
                      </span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        Pune, IN
                      </span>
                      <span className="airport">
                        {" "}
                        {segment.ArrivalCity.AIRPORTNAME}
                        {/* , Terminal{" "}{segment.Destination.Airport.Terminal}{" "} */}
                      </span>
                    </li>
                  </ul>
                  <div className="clearfix" />
                  {index <
                    flight.OriginDestinationOptions[1].FlightSegments.length -
                      1 && (
                    <div className="layover_time">
                      <div className="layover_txt">
                        Layover:
                        <span>
                          {formatLayoverTime(
                            segment.ArrivalDateTime,
                            flight.OriginDestinationOptions[1].FlightSegments[
                              index + 1
                            ].DepartureDateTime
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )
            )}
          {/* <div className="layover_time">
                    <div className="layover_txt">
                      Layover:<span>2h : 10m</span>
                    </div>
                  </div>
                  <ul className="list-search-result booking_list forMinScreenul">
                    <li className="flight_name">
                      <img
                        src="https://images-platform.99static.com//ee5DpJb0wvpRSgjbg0FEFl-RZsc=/943x0:1534x591/fit-in/590x590/99designs-contests-attachments/60/60257/attachment_60257611"
                        alt=""
                        style={{ width: "50px" }}
                      />
                      <div className="name">
                        {" "}
                        AirAsia
                        <span className="flight_no">I5-721</span>
                        <span className="flightBookingHiddenTitle">
                          Economy
                        </span>
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">
                        {" "}
                        Delhi, IN
                      </span>
                      <strong>21:25</strong>
                      <span className="date">Mon, 11 Mar 2024</span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        Delhi, IN
                      </span>
                      <span className="airport">
                        {" "}
                        Indira Gandhi Airport , Terminal 3
                      </span>
                    </li>
                    <li className="flight_amenties">
                      <div className="top">
                        <span className="duration">
                          <i className="fa fa-clock" />
                          02h 10m
                        </span>
                        <span className="flightDetailSpantag"></span>
                        <span className="grey_rtbrder">|</span>{" "}
                        
                        <span className="flightBookingHiddenDelhi">
                          Economy{" "}
                        </span>
                      </div>

                      <div className="middle">
                        <span className="txt">
                          <i className="fa fa-plane" /> Flight
                        </span>
                      </div>

                      <div className="bottom">
                        <span className="wght">15KG</span>
                        
                        <span className="grey_rtbrder">|</span>
                        <span className="refundable">Refundable</span>
                      </div>
                    </li>
                    <li className="flight_time">
                      <span className="flightBookingHiddenDelhi">Pune, IN</span>
                      <strong>23:35</strong>
                      <span className="date">Mon, 11 Mar 2024</span>
                      <span className="flightBookingHiddenTitle">
                        {" "}
                        Pune, IN
                      </span>
                      <span className="airport"> Lohegaon, Terminal </span>
                    </li>
                  </ul>
                  <div className="clearfix" /> */}
        </div>
      )}
    </div>
  );
};

export default DepatureDetail;
