import React, { useEffect, useState } from "react";
import FullDetail from "./FullDetail";
// import Combine from '../common/footer/Combine'
// import { useParams } from 'react-router-dom'
import axios from "axios";
import Slider from "react-slick";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
// import Reuse from "./Reuse";
import Reuse from "./Reuse";
import "./DetailCard.css";
import { WiDayCloudy } from "react-icons/wi";

import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiNightSleep } from "react-icons/gi";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <BsArrowLeftCircle
        style={{ color: "#9DC541", fontSize: "35px", marginRight: "30px" }}
        className="hidden md:block xl:block translate-x-[-15px]"
      />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <BsArrowRightCircle
        style={{ color: "#9DC541", fontSize: "35px", marginRight: "10px" }}
        className="hidden md:block xl:block translate-x-[15px]"
      />
    </div>
  );
};

const DeatailCard = () => {
  const [data, setData] = useState([]);
  const { pack, fullDetails } = useParams();
  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(
        `https://tripoholidays.in/api/destinations/${pack}/${fullDetails}`
      );
      if (res) {
        setData(res.data.data);
      }
    };
    fetch();
  }, []);
  console.log(data);

  var settings = {
    dots: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    infinite: true,
    speed: 100,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
    slidesToShow: "auto",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-[#ffffff] main_RESUSEA">
      {data && Object.keys(data).length > 0 && (
        <div>
          <div>
            <div className="detailCardMainDiv">
              <div className="detailCardMainDivInner1">
                <h2 className="">{data.packagedetail.name}</h2>
                <p className="detailCardMainDivInner1para1">Tour Code:</p>
                <p className="detailCardMainDivInner1para2">
                  <GiNightSleep color="#f8a500" /> {data.packagedetail.nights}{" "}
                  Nights / <WiDayCloudy color="#f8a500" />{" "}
                  {data.packagedetail.days} Days
                </p>
              </div>
              <div className="detailCardMainDivInner2">
                <p className="detailCardMainDivInner2p1">Starting From</p>
                <p className="detailCardMainDivInner2p2">
                  ₹{data.packagedetail.offer_price}
                </p>
                <p className="detailCardMainDivInner2p3">
                  Per Person on twin sharing
                </p>
              </div>
            </div>
            <FullDetail data={data} />
          </div>

          {/* <div className="detailCardMainDivInner3"> */}
          <Container>
            <h2 className="detailCardMainDivInner3head">Similar Packages</h2>
            {/* <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                "@1.50": {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                {data.similar_packages.map((item) => (
                  <Reuse
                    nam={item.name}
                    image={item.image}
                    detail={item.details_day_night}
                    day={item.day}
                    night={item.night}
                    price={item.price}
                  />
                ))}
              </SwiperSlide>
            </Swiper> */}
            <Slider {...settings}>
              {data.similar_packages.map((item) => (
                <Reuse
                  nam={item.name}
                  image={item.image}
                  detail={item.details_day_night}
                  day={item.day}
                  night={item.night}
                  price={item.price}
                />
              ))}
            </Slider>
          </Container>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default DeatailCard;
